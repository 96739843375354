import React from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { DashboardSelectEndAdornment } from "../../inputs/dashboard-filter/dashboard-select-end-adornment";
import { DateRangePopper } from "../../poppers";
import { DateRangeStyledInput } from "../../../../util/theme/styled-components";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.date-range-filter.css";
import { FilterInputStyles } from "../../types";
import { Box } from "@mui/material";
import { DateRangeCalenderFooter } from "../../footers";
import { CalendarContainerProps } from "react-datepicker/dist/calendar_container";

interface Props {
	localizedText: string;
	styles: FilterInputStyles;
	anchorEl: HTMLElement | null;
	clearDates: () => void;
	openCalendar: () => void;
	closeCalendar: () => void;
	submitDates: () => void;
	calendarIsOpen: boolean;
	monthsShown: number;
	onChange: (dates: [start: any, end: any]) => void;
	minDate: Date;
	maxDate: Date;
	startDate: Date | undefined;
	endDate: Date | undefined;
	usedLocale: string;
}

export const DateRangeFilter: React.FC<Props> = ({
	localizedText,
	styles: { endImage: endImageStyles, inputField: inputFieldStyles },
	anchorEl,
	clearDates,
	openCalendar,
	closeCalendar,
	submitDates,
	calendarIsOpen,
	monthsShown,
	onChange,
	minDate,
	maxDate,
	startDate,
	endDate,
	usedLocale
}) => {
	const conditionalInputProps = startDate ? {} : { value: localizedText };

	return (
		<DatePicker
			autoComplete="off"
			customInput={
				<DateRangeStyledInput
					type="button"
					variant="outlined"
					InputProps={{
						endAdornment: (
							<DashboardSelectEndAdornment
								handleClick={openCalendar}
								styles={{
									...endImageStyles,
									box: {
										...endImageStyles.box,
										transform: calendarIsOpen ? "rotate(180deg)" : "rotate(0deg)",
										background: "transparent",
										display: "flex",
										alignItems: "center",
										justifyContent: "center"
									}
								}}
							/>
						),
						sx: { background: inputFieldStyles.background },
						...conditionalInputProps
					}}
					className="date-range-input"
					onClick={openCalendar}
				/>
			}
			clearButtonClassName="clear-button"
			selectsRange
			showPopperArrow={false}
			minDate={minDate}
			maxDate={maxDate}
			startDate={startDate}
			endDate={endDate}
			onChange={onChange}
			open={calendarIsOpen}
			onInputClick={openCalendar}
			isClearable
			dropdownMode="select"
			showPreviousMonths
			monthsShown={monthsShown}
			scrollableMonthYearDropdown
			locale={usedLocale}
			placeholderText={localizedText}
			popperContainer={({ children }) => (
				<DateRangePopper
					closeCalendar={closeCalendar}
					open={calendarIsOpen}
					anchorEl={anchorEl}
				>
					{children}
				</DateRangePopper>
			)}
			calendarContainer={
				(({ className, children }) => (
					<CalendarContainer className={className}>
						<Box height="100%" width="max-content">
							{children}
						</Box>
						<DateRangeCalenderFooter
							clearDates={clearDates}
							submitDates={submitDates}
							disabled={!startDate}
						/>
					</CalendarContainer>
				)) as React.FC<CalendarContainerProps>
			}
		/>
	);
};
