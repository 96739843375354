import { Box, Stack } from "@mui/material";
import {
	DashboardSortArrow,
	DashboardSortDownArrow,
	DashboardSortUpArrow,
	GenericBody
} from "../../views";
import { DashboardTableCheckbox } from "../theme/styled-components";
import { useTableContext } from "../contexts/table.context";
import { SortOrder } from "../contexts/types";

interface SortOptions {
	sortable: boolean;
	isSorted: boolean;
	sortOrder: SortOrder;
}

type ContentGetter = (
	text: string,
	sortOptions: SortOptions,
	textColor?: string
) => JSX.Element;

export const DashboardHeaderCellContentAdaptor: Record<string, ContentGetter> = {
	delete: () => <Box />,
	text: (text: string, { sortable, isSorted, sortOrder }, textColor = "white") => {
		const EndAdornment = isSorted
			? sortOrder === "-"
				? DashboardSortDownArrow
				: DashboardSortUpArrow
			: DashboardSortArrow;

		return (
			<Stack direction="row" alignItems="center" spacing="0.13rem">
				<GenericBody
					sx={{ width: "100%", whiteSpace: "nowrap" }}
					variant={`body3-${textColor}-semi-bold`}
					text={text}
				/>
				{sortable ? <EndAdornment color={textColor} /> : null}
			</Stack>
		);
	},
	select: () => {
		const { allElementsSelected } = useTableContext();

		return <DashboardTableCheckbox checked={allElementsSelected} variant="white" />;
	},
	inspect: () => <Box />
};
