import {
	ClientHomepageStack,
	ClientHomepageTableHeaderStack,
	DashboardTablePaper
} from "../../../util/theme/styled-components";
import { TimeToggleContainer } from "../../containers";
import { DashboardTableHeaderRow } from "../rows";
import { DashboardTableBody } from "../bodies";
import { DashboardTableWrapper } from "./dashboard.table";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { MobileTable } from "./mobile.table";
import { DashboardTableFooter } from "../footers";
import { useTableContext } from "../../../util/contexts/table.context";
import { EmptyTableViewContent } from "../contents";
import { ClientHomepageTableHeading } from "../headings";

export const ClientHomepageTable: React.FC = () => {
	const { isMobile } = useResponsive();
	const { tableElements } = useTableContext();

	const numberOfDisplayableChecks = tableElements.length;

	const [desktopTable, mobileTable] =
		numberOfDisplayableChecks > 0
			? [
					<DashboardTablePaper $inBlock>
						<DashboardTableWrapper>
							<DashboardTableHeaderRow variant="grey" />
							<DashboardTableBody />
						</DashboardTableWrapper>
						<DashboardTableFooter pageSizeFixed />
					</DashboardTablePaper>,
					<MobileTable />
			  ]
			: [
					<DashboardTablePaper $inBlock>
						<DashboardTableWrapper>
							<DashboardTableHeaderRow variant="grey" />
						</DashboardTableWrapper>
						<EmptyTableViewContent />
					</DashboardTablePaper>,
					<MobileTable />
			  ];

	const [tableContent, containerStyles] = isMobile
		? [mobileTable, { border: "none" }]
		: [desktopTable, {}];

	return (
		<ClientHomepageStack sx={containerStyles}>
			<ClientHomepageTableHeaderStack>
				<ClientHomepageTableHeading />
				<TimeToggleContainer />
			</ClientHomepageTableHeaderStack>
			{tableContent}
		</ClientHomepageStack>
	);
};
