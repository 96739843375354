import { Avatar, styled } from "@mui/material";
import { transientOptions } from "../options";

export const UserInitialsAvatar = styled(
	Avatar,
	transientOptions
)<{ $button?: boolean }>(({ $button, theme }) => {
	const buttonStyleOverrides = $button
		? {
				cursor: "pointer",
				":hover": {
					background: `${theme.palette.secondary.main}2a`
				},
				":active": {
					background: `${theme.palette.secondary.main}3a`
				}
		  }
		: {};

	return {
		background: `${theme.palette.secondary.main}1a`,
		...buttonStyleOverrides
	};
});
