import React from "react";
import { useLanguageContext } from "../../util/contexts/language.context";
import { BranchDAO, DocumentDAO, FaqsDAO, NewsDAO } from "../../util/generators/types";
import {
	PublicBranchesBaseGenerator,
	PublicDocumentsGenerator,
	PublicFaqGenerator,
	PublicGeneratorFactory,
	PublicNewsGenerator
} from "../../util/generators/public.generator";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { useResponsive } from "../../util/hooks/useResponsive.hook";
import { HeaderSearchButton, SearchDropdown } from "../components";
import { ActionWithDelayedCallback } from "../../util/ActionWithDelayedCallback";
import { LanguageEnum } from "../../util/dictionaries/types";
import { ItemFilter } from "./types";

interface Props {
	openTrigger: boolean;
	setOpenTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const searchButtonWidth = "12.3rem";
export const searchDropdownWidth = { xs: "100vw", md: "55.2vw", lg: "50.6rem" };

const widthTransitionDuration = 650;

const initialMargin = -800;
const finalMargin = 8;
const marginTransitionDuration = 750;

const fastTransitionDuration = 300;

export const SearchContainer: React.FC<Props> = ({ openTrigger, setOpenTrigger }) => {
	const { language } = useLanguageContext();
	const { isMobile } = useResponsive();

	const [filter, setFilter] = React.useState("");

	const [documents, setDocuments] = React.useState<DocumentDAO[]>([]);
	const [faqs, setFaqs] = React.useState<FaqsDAO[]>([]);
	const [news, setNews] = React.useState<NewsDAO[]>([]);
	const [branches, setBranches] = React.useState<BranchDAO[]>([]);

	const [closeTrigger, setCloseTrigger] = React.useState(false);

	const [selectedFilter, setSelectedFilter] = React.useState<ItemFilter>("none");

	const initialWidth = isMobile ? searchDropdownWidth : searchButtonWidth;

	const [width, setWidth] = React.useState<string | Record<string, string>>(initialWidth);
	const [marginTop, setMarginTop] = React.useState<number>(initialMargin);
	const [anchorElement, setAnchorElement] = React.useState<undefined | null>(null);

	const paginationOptions = {
		pagination: { pageSize: 10 }
	};

	const documentsGenerator = new PublicDocumentsGenerator(paginationOptions);
	const faqsGenerator = new PublicFaqGenerator(paginationOptions);
	const newsGenerator = new PublicNewsGenerator(paginationOptions);
	const branchesGenerator = new PublicBranchesBaseGenerator(paginationOptions);

	const handleChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		event.preventDefault();
		const {
			target: { value }
		} = event;
		setFilter(value);
	};

	const handleItemFilterChange = (itemFilter: ItemFilter) =>
		setSelectedFilter(itemFilter);

	const searchRef = React.useRef();

	const setOpen = () => {
		setAnchorElement(searchRef.current);
		setOpenTrigger(true);
		setCloseTrigger(false);
	};

	const setClose = () => {
		setCloseTrigger(true);
	};

	const expandSearchBar = () => setWidth(searchDropdownWidth);
	const shrinkSearchBar = () => setWidth(searchButtonWidth);

	const moveSearchDropdownDown = () => setMarginTop(finalMargin);
	const moveSearchDropdownUp = () => setMarginTop(initialMargin);

	const closeSearchBar = () => setOpenTrigger(false);

	const initiateMobileOpeningAnimation = new ActionWithDelayedCallback(
		expandSearchBar,
		moveSearchDropdownDown,
		fastTransitionDuration
	);

	const expandSearchBarWithDelay = new ActionWithDelayedCallback(
		() => null,
		expandSearchBar,
		fastTransitionDuration
	);

	const initiateDesktopOpeningAnimation = new ActionWithDelayedCallback(
		expandSearchBarWithDelay.start,
		moveSearchDropdownDown,
		fastTransitionDuration
	);

	const openingAnimation = () => {
		let animation: ActionWithDelayedCallback;
		let actions: ActionWithDelayedCallback[];
		if (isMobile) {
			animation = initiateMobileOpeningAnimation;
			actions = [initiateMobileOpeningAnimation];
		} else {
			animation = initiateDesktopOpeningAnimation;
			actions = [expandSearchBarWithDelay, initiateDesktopOpeningAnimation];
		}
		animation.start();
		return actions;
	};

	const moveSearchDropdownUpWithDelay = new ActionWithDelayedCallback(
		() => null,
		moveSearchDropdownUp,
		fastTransitionDuration
	);

	const initiateMobileClosingAnimation = new ActionWithDelayedCallback(
		moveSearchDropdownUpWithDelay.start,
		closeSearchBar,
		marginTransitionDuration
	);

	const shrinkSearchBarAfterDropdownGoesUp = new ActionWithDelayedCallback(
		moveSearchDropdownUpWithDelay.start,
		shrinkSearchBar,
		widthTransitionDuration
	);

	const initiateDesktopClosingAnimation = new ActionWithDelayedCallback(
		shrinkSearchBarAfterDropdownGoesUp.start,
		closeSearchBar,
		marginTransitionDuration
	);

	const closingAnimation = () => {
		let animation: ActionWithDelayedCallback;
		let actions: ActionWithDelayedCallback[];
		if (isMobile) {
			animation = initiateMobileClosingAnimation;
			actions = [moveSearchDropdownUpWithDelay, initiateMobileClosingAnimation];
		} else {
			animation = initiateDesktopClosingAnimation;
			actions = [
				moveSearchDropdownUpWithDelay,
				shrinkSearchBarAfterDropdownGoesUp,
				initiateDesktopClosingAnimation
			];
		}
		animation.start();
		return actions;
	};

	const setAnimationActions = (
		trigger: boolean,
		animation: () => ActionWithDelayedCallback[]
	) => (trigger ? animation() : []);

	const resetSearchDropdown = () => {
		setSelectedFilter("none");
		setFilter("");
	};

	const initiateAnimation = (
		animationTrigger: boolean,
		animation: () => ActionWithDelayedCallback[]
	) => {
		const actions = setAnimationActions(animationTrigger, animation);
		return () => actions.forEach(action => action.cancel());
	};

	React.useEffect(() => {
		return initiateAnimation(closeTrigger, closingAnimation);
	}, [closeTrigger]);

	React.useEffect(() => {
		resetSearchDropdown();
		return initiateAnimation(openTrigger, openingAnimation);
	}, [openTrigger]);

	const documentSearchColumns = ["name", "description", "fileExtension"];
	const faqSearchColumns = ["question", "answer", "category"];
	const newsSearchColumns = ["title", "description"];
	const branchesSearchColumns = ["name", "city", "address"];

	useGeneratorEffect(
		generatorEffect(
			documentsGenerator,
			language,
			documentSearchColumns,
			filter,
			setDocuments,
			"-date"
		),
		[filter],
		!filter
	);
	useGeneratorEffect(
		generatorEffect(faqsGenerator, language, faqSearchColumns, filter, setFaqs),
		[filter],
		!filter
	);
	useGeneratorEffect(
		generatorEffect(newsGenerator, language, newsSearchColumns, filter, setNews, "-date"),
		[filter],
		!filter
	);
	useGeneratorEffect(
		generatorEffect(
			branchesGenerator,
			language,
			branchesSearchColumns,
			filter,
			setBranches
		),
		[filter],
		!filter
	);

	const ctrlKHandler = React.useCallback((event: KeyboardEvent) => {
		if ((event.ctrlKey || event.metaKey) && event.key === "k") {
			event.preventDefault();
			setOpen();
		}
	}, []);

	React.useEffect(() => {
		window.addEventListener("keydown", ctrlKHandler);

		return () => window.removeEventListener("keydown", ctrlKHandler);
	}, [ctrlKHandler]);

	return (
		<>
			<HeaderSearchButton
				isOpen={openTrigger}
				setOpen={setOpen}
				filter={filter}
				searchRef={searchRef}
			/>
			<SearchDropdown
				anchorElement={anchorElement}
				handleChange={handleChange}
				handleItemFilterChange={handleItemFilterChange}
				setClose={setClose}
				openCondition={openTrigger}
				filter={filter}
				filteredFaqs={faqs}
				filteredDocuments={documents}
				filteredNews={news}
				filteredBranches={branches}
				selectedFilter={selectedFilter}
				width={width}
				marginTop={marginTop}
			/>
		</>
	);
};

const createSearchQuery = (searchColumns: string[], filter: string) =>
	searchColumns.map(column => `[${column}][$ilike]=${filter}`).join("&");

const generatorEffect = <DAO,>(
	generator: InstanceType<ReturnType<typeof PublicGeneratorFactory<DAO, DAO[]>>>,
	language: LanguageEnum,
	searchCOlumns: string[],
	filter: string,
	setElements: React.Dispatch<React.SetStateAction<DAO[]>>,
	sort = ""
) => ({
	effect: () =>
		generator.manyForLanguage(
			language,
			createSearchQuery(searchCOlumns, filter) + `&sort=${sort}`
		),
	onSuccess: setElements,
	callback: generator.abort
});
