import React from "react";
import { ActivitiesAdminCommunicator } from "../../communicators/lider.http.communicator";
import { FastEditSidebarView, FormSnackbar, ConfirmationDialog } from "../components";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { ReleaseStatusDAO } from "../../util/generators/types";
import { useAuthFormStatus } from "../../util/hooks/useAuthFormStatus.hook";
import { useRefreshContext } from "../../util/contexts/refresh.context";
import { CommunicatorResponse } from "../../communicators/types";
import { useSocketEvent } from "../../util/hooks/useSocketEvent.hook";

export const FastEditSidebar: React.FC = () => {
	const { crudRefreshTrigger } = useRefreshContext();
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [drawerOpen, setDrawerOpen] = React.useState(false);

	const [isPublished, setIsPublished] = React.useState(true);
	const [lastPublishDate, setLastPublishDate] = React.useState(new Date(0));

	const {
		submissionStatus,
		handleSubmissionError,
		setSubmissionInProgress,
		resetSubmissionStatus,
		setSubmissionStatus
	} = useAuthFormStatus();

	const activitiesCommunicator = new ActivitiesAdminCommunicator();

	const setPublishStatus = (response: CommunicatorResponse<ReleaseStatusDAO>) => {
		if (!(response && response.data)) return;
		setIsPublished(response.data.released);
		setLastPublishDate(new Date(response.data.lastReleasedAt));
	};

	useGeneratorEffect(
		{
			effect: activitiesCommunicator.getPublishStatus,
			onSuccess: setPublishStatus,
			callback: activitiesCommunicator.abort
		},
		[crudRefreshTrigger, isPublished]
	);

	const handleDialogToggle = () => setDialogOpen(previous => !previous);
	const handleDialogClose = () => setDialogOpen(false);

	const handleDrawerToggle = () => setDrawerOpen(previous => !previous);
	const handleDrawerClose = () => setDrawerOpen(false);

	const publish = () =>
		activitiesCommunicator
			.publishStagingData()
			.then(setSubmissionInProgress)
			.catch(handleSubmissionError)
			.finally(() => {
				handleDialogClose();
				handleDrawerClose();
			});

	const updateReleaseStatus = ({ released, releaseStatus }: ReleaseStatusDAO) => {
		setIsPublished(released);
		setSubmissionStatus(releaseStatus);
	};

	useSocketEvent("releaseStagingData", updateReleaseStatus);

	return (
		<>
			<FastEditSidebarView
				handleDialogToggle={handleDialogToggle}
				handleDrawerToggle={handleDrawerToggle}
				drawerOpen={drawerOpen}
				isPublished={isPublished}
				lastPublishDate={lastPublishDate}
			/>
			<FormSnackbar
				autoHideDuration={5000}
				handleClose={resetSubmissionStatus}
				submissionStatus={submissionStatus}
				formVariant="publish"
			/>
			<ConfirmationDialog
				handleConfirmation={publish}
				dialogOpen={dialogOpen}
				variant="publish"
			/>
		</>
	);
};
