import { Skeleton, SkeletonProps } from "@mui/material";

export const ButtonSkeleton: React.FC<SkeletonProps> = ({ sx, ...props }) => (
	<Skeleton
		animation="wave"
		width="100%"
		height="1.75rem"
		variant="rounded"
		sx={{ minWidth: "1.75rem", ...sx }}
		{...props}
	/>
);
