import { Box, Stack } from "@mui/material";
import { useTableContext } from "../../../util/contexts/table.context";
import { DashboardFiltersContainer } from "../../containers";
import { DashboardTablePaper } from "../../../util/theme/styled-components";
import { DashboardTableWrapper } from "./dashboard.table";
import { DashboardTableHeaderRow } from "../rows";
import { DashboardTableBody } from "../bodies";
import { DashboardTableFooter } from "../footers";
import { EmptyTableViewContent } from "../contents";

export const AdminActivitiesTable: React.FC = () => {
	const { tableElements } = useTableContext();
	const numberOfTableEntries = tableElements.length;

	const tableContent = !!numberOfTableEntries ? (
		<Stack spacing="1rem">
			<Box pl="1rem">
				<DashboardFiltersContainer />
			</Box>
			<DashboardTablePaper>
				<DashboardTableWrapper stickyHeader>
					<DashboardTableHeaderRow variant="grey" />
					<DashboardTableBody />
				</DashboardTableWrapper>
				<DashboardTableFooter />
			</DashboardTablePaper>
		</Stack>
	) : (
		<Stack spacing="1rem">
			<Box pl="1rem">
				<DashboardFiltersContainer />
			</Box>
			<DashboardTablePaper>
				<DashboardTableWrapper stickyHeader>
					<DashboardTableHeaderRow variant="grey" />
				</DashboardTableWrapper>
				<EmptyTableViewContent />
			</DashboardTablePaper>
		</Stack>
	);

	return tableContent;
};
