import { Stack, Divider } from "@mui/material";
import { Identifiable } from "../../../util/adaptors/types";
import { DashboardTableConfig } from "../../../util/configs/dashboard-table";
import { useTableContext } from "../../../util/contexts/table.context";
import { MobileTableItemStack } from "../../../util/theme/styled-components";
import { MobileTableItemHeader } from "../headers";
import { DashboardBodyCellContent } from "../contents";
import { MobileTableItemBodyRow } from "../rows";

interface Props {
	element: Identifiable;
}

export const MobileTableItem: React.FC<Props> = ({ element }) => {
	const { handleSelectElement, selectedElements, table, isLoading } = useTableContext();

	const { dataColumns, footerColumn, tableInteractionDisabled } =
		DashboardTableConfig[table];

	const tableInteraction = () =>
		tableInteractionDisabled || isLoading ? null : handleSelectElement(element, "edit");

	const rows = dataColumns
		.filter(({ isHeaderColumn, isFooterColumn }) => !isHeaderColumn && !isFooterColumn)
		.map((column, index) => (
			<MobileTableItemBodyRow key={index} column={column} element={element} />
		));

	const footerRow = footerColumn ? (
		<DashboardBodyCellContent element={element} column={footerColumn} />
	) : null;

	const isSelected = selectedElements.some(
		({ id: selectedId }) => selectedId === element.id
	);

	return (
		<MobileTableItemStack
			direction="column"
			$isSelected={isSelected}
			py="0.75rem"
			spacing="0.5rem"
		>
			<MobileTableItemHeader
				element={element}
				isSelected={isSelected}
				handleClick={tableInteraction}
			/>
			<Stack
				direction="column"
				px="1rem"
				spacing="0.5rem"
				width="100%"
				pb={footerColumn ? "0.5rem" : "0"}
			>
				<Stack
					spacing="0.5rem"
					width="100%"
					onClick={tableInteraction}
					divider={
						<Divider variant={isSelected ? "fullwidth-secondary" : "fullwidth-grey"} />
					}
					pt="0.25rem"
					pb={footerColumn ? "0.25rem" : "0"}
				>
					{rows}
				</Stack>
				{footerRow}
			</Stack>
		</MobileTableItemStack>
	);
};
