import { useTableContext } from "../../../util/contexts/table.context";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import {
	AdminHomepageTableBox,
	DashboardTablePaper
} from "../../../util/theme/styled-components";
import { DashboardTableBody } from "../bodies";
import { EmptyTableViewContent } from "../contents";
import { DashboardTableHeaderRow } from "../rows";
import { DashboardTableWrapper } from "./dashboard.table";
import { MobileTable } from "./mobile.table";
import { AdminHomepageTableHeader } from "../headers";

import adminActivitiesIcon from "../../../assets/images/dashboard/dashboard-admin-activities.icon.svg";

interface Props {
	openDialog: () => void;
}

export const AdminHomepageTable: React.FC<Props> = ({ openDialog }) => {
	const { isMobile } = useResponsive();
	const { tableElements } = useTableContext();

	const numberOfDisplayableAdminActivities = tableElements.length;

	const desktopTable =
		numberOfDisplayableAdminActivities > 0 ? (
			<DashboardTablePaper $inBlock>
				<DashboardTableWrapper>
					<DashboardTableHeaderRow variant="grey" />
					<DashboardTableBody />
				</DashboardTableWrapper>
			</DashboardTablePaper>
		) : (
			<DashboardTablePaper $inBlock>
				<DashboardTableWrapper>
					<DashboardTableHeaderRow variant="grey" />
				</DashboardTableWrapper>
				<EmptyTableViewContent />
			</DashboardTablePaper>
		);

	const [tableContent, containerStyles] = isMobile
		? [<MobileTable loadMoreDisabled />, { border: "none", boxShadow: "none" }]
		: [desktopTable];

	return (
		<AdminHomepageTableBox sx={containerStyles}>
			<AdminHomepageTableHeader
				handleClick={openDialog}
				imageSrc={adminActivitiesIcon}
				localizationKey="dashboard_sidebar_activities_text"
				padding={{ xs: "1.25rem 0", md: "1.25rem 2rem" }}
			/>
			{tableContent}
		</AdminHomepageTableBox>
	);
};
