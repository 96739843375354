import { FilterOption } from "../../../views/types";
import { TableAction } from "../../contexts/types";
import { ColumnArgs } from "./types";

type ColumnTypes =
	| "adminActivity"
	| "adminActivityUserInformation"
	| "boolean"
	| "chip"
	| "currency"
	| "dateRange"
	| "oneYearDateRange"
	| "dateRangeWithTime"
	| "delete"
	| "index"
	| "inspect"
	| "resourceName"
	| "select"
	| "text"
	| "userInformation"
	| "userRoles";

export class Column {
	displayBy: string;
	type: ColumnTypes = "text";
	sortable = true;
	requestBy: string;
	hidden = false;
	variableLocalizationKey: string | null = null;

	action: TableAction = "edit";
	defaultSort: null | "asc" | "desc" = null;
	searchable = false;
	filterable = false;

	possibleValues: any[] = [];
	filterOptions: FilterOption[] = [];

	rowOrder = 0;
	isHeaderColumn = false;
	isFooterColumn = false;

	constructor({ displayBy, ...args }: ColumnArgs) {
		this.displayBy = displayBy;
		this.requestBy = displayBy;
		Object.assign(this, args);
	}
}
