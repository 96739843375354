import { Box } from "@mui/material";
import React from "react";
import { Identifiable } from "../adaptors/types";
import { TableEnum } from "../types/dashboard-table.types";
import {
	PrivateEntityByPrimaryKeyGeneratorDictionary,
	PrivateGeneratorFactory
} from "../generators/private.generator";
import { useUpdateEffect } from "../hooks/useUpdateEffect.hook";
import { DashboardTableConfig, FilterableColumn } from "../configs/dashboard-table";
import { Column } from "../configs/dashboard-table/column";
import { LocalizationObject, SortOrder, SortedField, TableAction } from "./types";
import { useLanguageContext } from "./language.context";

interface ITableContext {
	clearSelectedElements: () => void;
	handleDelete: (callback: () => void) => void;
	handleLoadMore: () => void;
	handlePageSizeChange: (pageSize: number) => void;
	handleSelectAll: () => void;
	handleSelectElement: (element: Identifiable, tableAction: TableAction) => void;
	handleSortClick: (requestBy: string) => void;
	paginateToNextPage: () => void;
	paginateToPage: (value: number) => void;
	paginateToPreviousPage: () => void;
	refreshTable: () => void;
	setFilteredColumns: React.Dispatch<React.SetStateAction<FilterableColumn[]>>;
	setFilterQueryString: (queryString: string) => void;
	setSearchQueryString: (queryString: string) => void;
	setSortedField: (sortedField: SortedField) => void;
	setSortOrder: (sortOrder: SortOrder) => void;
	allElementsSelected: boolean;
	filteredColumns: FilterableColumn[];
	filterQueryString: string;
	isLoading: boolean;
	pageCount: number;
	pageNumber: number;
	pageSize: number;
	selectedElements: Identifiable[];
	shouldLoadMore: boolean;
	sortedField: SortedField;
	sortOrder: SortOrder;
	sortQueryString: string;
	table: TableEnum;
	tableAction: TableAction;
	tableKey: TableEnum;
	tableElements: Identifiable[];
	totalElementCount: number;
}

export const TableContext = React.createContext<ITableContext>({
	clearSelectedElements: () => null,
	handleDelete: () => null,
	handleLoadMore: () => null,
	handlePageSizeChange: () => null,
	handleSelectAll: () => null,
	handleSelectElement: () => null,
	handleSortClick: () => null,
	paginateToNextPage: () => null,
	paginateToPage: () => null,
	paginateToPreviousPage: () => null,
	refreshTable: () => null,
	setFilteredColumns: () => null,
	setFilterQueryString: () => null,
	setSearchQueryString: () => null,
	setSortedField: () => null,
	setSortOrder: () => null,
	allElementsSelected: false,
	filteredColumns: [],
	filterQueryString: "",
	isLoading: false,
	pageCount: 1,
	pageNumber: 0,
	pageSize: 10,
	selectedElements: [],
	shouldLoadMore: false,
	sortedField: null,
	sortOrder: "",
	sortQueryString: "",
	table: TableEnum.documents,
	tableAction: null,
	tableKey: TableEnum.documents,
	tableElements: [],
	totalElementCount: -1
});

export const useTableContext = () => React.useContext(TableContext);

interface ProviderProps {
	table: TableEnum;
	tableKey?: TableEnum;
	pageSize?: number;
}

export const TableProvider: React.FC<ProviderProps> = ({
	children,
	table,
	tableKey = table,
	pageSize: initialPageSize = 10,
	...props
}) => {
	const { localizationObject } = useLanguageContext();

	const { defaultSortedField, defaultSortOrder } = getDefaultSortOptions(table);

	const [sortedField, setSortedField] = React.useState<SortedField>(defaultSortedField);
	const [sortOrder, setSortOrder] = React.useState<SortOrder>(defaultSortOrder);
	const [pageNumber, setPageNumber] = React.useState(0);
	const [pageSize, setPageSize] = React.useState<number>(initialPageSize);
	const [tableElements, setTableElements] = React.useState<Identifiable[]>([]);
	const [filterQueryString, setFilterQueryString] = React.useState("");
	const [searchQueryString, setSearchQueryString] = React.useState("");
	const [selectedElements, setSelectedElements] = React.useState<Identifiable[]>([]);
	const [tableAction, setTableAction] = React.useState<TableAction>(null);
	const [generatorActiveRequestCount, setGeneratorActiveRequestCount] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(true);
	const [filteredColumns, setFilteredColumns] = React.useState<FilterableColumn[]>([]);

	const sortQueryString = sortedField ? `sort=${sortOrder}${sortedField}` : "";

	const queryString = `${filterQueryString}&${searchQueryString}&${sortQueryString}`;

	const tableHasBeenRefreshed = pageNumber === -1;

	const isGeneratorWorking = !!generatorActiveRequestCount;

	const isGeneratorWorkingRef = React.useRef(isGeneratorWorking);
	const updateIsLoadingRef = React.useRef(setIsLoading);

	const generatorRef = React.useRef(
		new PrivateEntityByPrimaryKeyGeneratorDictionary[table](
			{ pagination: { pageSize } },
			setGeneratorActiveRequestCount
		)
	);
	let generator = generatorRef.current;

	const allElementsSelected = generator.count === selectedElements.length;

	const paginateToPage = (value: number) => setPageNumber(value);

	const paginateToPreviousPage = () => paginateToPage(pageNumber - 1);

	const paginateToNextPage = () => paginateToPage(pageNumber + 1);

	const handlePageSizeChange = (pageSize: number) => setPageSize(pageSize);

	const clearSelectedElements = () => setSelectedElements([]);

	const handleSelectAll = () => {
		if (!allElementsSelected) return generator.all().then(setSelectedElements);
		clearSelectedElements();
	};

	const selectElement = (element: Identifiable) => {
		setSelectedElements(previous => [...previous, element]);
	};

	const deselectElement = (element: Identifiable) =>
		setSelectedElements(previous => previous.filter(({ id }) => id !== element.id));

	const handleSelectElement = (element: Identifiable, tableAction: TableAction) => {
		setTableAction(tableAction);
		const elementIsSelected = selectedElements.some(({ id }) => id === element.id);
		if (elementIsSelected) return deselectElement(element);
		selectElement(element);
	};

	const switchSortOrder = () => setSortOrder(previous => (previous === "" ? "-" : ""));

	const changeSortOrder = (sortedFieldHasBeenUpdated: boolean) => {
		if (sortedFieldHasBeenUpdated) return setSortOrder("-");
		switchSortOrder();
	};

	const handleSortClick = (requestBy: string) => {
		const sortedFieldHasBeenUpdated = sortedField !== requestBy;
		setSortedField(requestBy);
		changeSortOrder(sortedFieldHasBeenUpdated);
	};

	const deleteElement = (element: Identifiable) => generator.delete(element.id);

	const deleteSelectedElement = () => deleteElement(selectedElements[0]);

	const handleDelete = (callback: () => void) => deleteSelectedElement().then(callback);

	const refreshGenerator = () => {
		generatorRef.current = new PrivateEntityByPrimaryKeyGeneratorDictionary[table](
			{
				pagination: { pageSize }
			},
			setGeneratorActiveRequestCount
		);
		generator = generatorRef.current;
	};

	const refreshTable = () => {
		refreshGenerator();
		setPossibleValuesAndCreateFilterOptions(table, generator, localizationObject);
		clearSelectedElements();
		paginateToPage(-1);
	};

	const fetchData = () => generator.many(queryString, pageNumber);

	const fetchAndSetData = () => fetchData().then(setTableElements);

	const fetchNextPageData = () => generator.many(queryString);

	const updateTableElements = (elements: Identifiable[]) =>
		setTableElements(previous => [...previous, ...elements]);

	const handleLoadMore = () => fetchNextPageData().then(updateTableElements);

	React.useEffect(() => {
		refreshTable();
	}, [table]);

	useUpdateEffect(() => {
		refreshTable();
	}, [pageSize, queryString]);

	useUpdateEffect(() => {
		if (tableHasBeenRefreshed) return setPageNumber(0);
		fetchAndSetData();
	}, [pageNumber]);

	const minimumLoadingDuration = 500;

	useUpdateEffect(() => {
		isGeneratorWorkingRef.current = isGeneratorWorking;
		if (!isGeneratorWorking) return updateIsLoadingRef.current(false);

		setIsLoading(true);
		updateIsLoadingRef.current = () => null;
		setTimeout(() => {
			updateIsLoadingRef.current = setIsLoading;
			updateIsLoadingRef.current(isGeneratorWorkingRef.current);
		}, minimumLoadingDuration);
	}, [isGeneratorWorking]);

	const dummyElements = Array.from({ length: pageSize }, (_, i) => ({
		id: i.toString()
	}));

	const context = {
		clearSelectedElements,
		handleDelete,
		handleLoadMore,
		handlePageSizeChange,
		handleSelectAll,
		handleSortClick,
		handleSelectElement,
		paginateToPreviousPage,
		paginateToNextPage,
		paginateToPage,
		refreshTable,
		setFilteredColumns,
		setFilterQueryString,
		setSearchQueryString,
		setSortedField,
		setSortOrder,
		allElementsSelected,
		filteredColumns,
		filterQueryString,
		isLoading,
		pageCount: generator.pageCount + 1,
		pageNumber,
		pageSize,
		selectedElements,
		shouldLoadMore: generator.hasMore && tableElements.length > 0,
		sortedField,
		sortOrder,
		sortQueryString,
		table,
		tableAction,
		tableKey,
		tableElements: isLoading ? dummyElements : tableElements,
		totalElementCount: generator.count
	};

	return (
		<Box {...props}>
			<TableContext.Provider value={context}>{children}</TableContext.Provider>
		</Box>
	);
};

const setPossibleValuesAndCreateFilterOptions = async (
	table: TableEnum,
	generator: InstanceType<ReturnType<typeof PrivateGeneratorFactory>>,
	localizationObject: LocalizationObject
) => {
	const filterableColumns = DashboardTableConfig[table].filterable;
	const fetchOptionsForColumn = (column: Column) =>
		generator.options(
			column.requestBy,
			column.type.toLowerCase().includes("date")
				? column.type === "oneYearDateRange"
					? "date"
					: "dateRange"
				: "default"
		);

	const promises = filterableColumns.map(fetchOptionsForColumn);
	const setPossibleValuesAndCreateFilterOptionsForColumn = (
		options: any[],
		columnIndex: number
	) => {
		filterableColumns[columnIndex].possibleValues = options;
		filterableColumns[columnIndex].createFilterOptions(localizationObject);
	};
	return Promise.all(promises).then(responses => {
		responses.forEach(setPossibleValuesAndCreateFilterOptionsForColumn);
	});
};

const getDefaultSortOptions = (
	table: TableEnum
): {
	defaultSortOrder: SortOrder;
	defaultSortedField: SortedField;
} => {
	const columns = DashboardTableConfig[table].visible;
	const { requestBy, defaultSort } = columns.find(({ defaultSort }) => !!defaultSort) ?? {
		requestBy: null,
		defaultSort: "desc"
	};
	const defaultSortOrder = defaultSort === "asc" ? "-" : "";
	return { defaultSortedField: requestBy, defaultSortOrder };
};
