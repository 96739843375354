import { Dialog, Stack } from "@mui/material";
import { AdminDialogHeader } from "../headers";
import { AdminActivitiesTableContainer } from "../../containers";

interface Props {
	handleDialogClose: () => void;
	dialogOpen: boolean;
}

export const AdminActivitiesDialog: React.FC<Props> = ({
	handleDialogClose,
	dialogOpen
}) => (
	<Dialog
		data-cy="admin-activities-dialog"
		fullWidth={true}
		maxWidth="lg"
		open={dialogOpen}
		onClose={handleDialogClose}
		sx={{
			".MuiPaper-root": {
				borderRadius: "0.75rem",
				overflow: "hidden"
			}
		}}
	>
		<Stack
			direction="column"
			spacing="1rem"
			sx={{
				".MuiPaper-root": {
					borderRadius: "0rem"
				},
				".MuiTableContainer-root": {
					borderRadius: "0rem"
				}
			}}
		>
			<AdminDialogHeader
				localizationKey="dashboard_sidebar_activities_text"
				handleClose={handleDialogClose}
			/>
			<AdminActivitiesTableContainer />
		</Stack>
	</Dialog>
);
