import { Box, Stack } from "@mui/material";
import {
	ButtonSkeleton,
	CheckBoxSkeleton,
	ChipSkeleton,
	CircularSkeleton,
	TextSkeleton
} from "../../views/components/skeletons";

export const DashboardBodySkeletonCellAdaptor: Record<string, () => JSX.Element> = {
	adminActivity: () => <ChipSkeleton />,
	adminActivityUserInformation: () => (
		<Stack direction="row" spacing="0.75rem" alignItems="center">
			<CircularSkeleton />
			<TextSkeleton />
		</Stack>
	),
	boolean: () => <CheckBoxSkeleton />,
	chip: () => <ChipSkeleton />,
	currency: () => <TextSkeleton />,
	dateRange: () => <TextSkeleton />,
	oneYearDateRange: () => <TextSkeleton />,
	dateRangeWithTime: () => (
		<Box>
			<Stack direction="row" spacing="0.5rem" alignItems="center">
				<CheckBoxSkeleton sx={{ display: { xs: "none", md: "unset" } }} />
				<TextSkeleton />
			</Stack>
			<Stack direction="row" spacing="0.5rem" alignItems="center">
				<CheckBoxSkeleton sx={{ display: { xs: "none", md: "unset" } }} />
				<TextSkeleton />
			</Stack>
		</Box>
	),
	delete: () => <ButtonSkeleton />,
	index: () => <CircularSkeleton />,
	inspect: () => <ButtonSkeleton sx={{ minWidth: "4rem" }} />,
	resourceName: () => (
		<Stack
			direction="row"
			spacing={{ xs: "0.375rem", md: "0.75rem" }}
			alignItems="center"
		>
			<CheckBoxSkeleton />
			<TextSkeleton />
		</Stack>
	),
	select: () => <CheckBoxSkeleton />,
	text: () => <TextSkeleton />,
	userInformation: () => (
		<Stack direction="row" spacing="1rem" alignItems="center">
			<CircularSkeleton />
			<TextSkeleton />
		</Stack>
	),
	userRoles: () => (
		<Stack direction="row" spacing="0.375rem" alignItems="center">
			<ChipSkeleton />
			<ChipSkeleton />
			<ChipSkeleton />
		</Stack>
	)
};
