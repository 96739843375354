import React from "react";

export const useGeneratorEffect: (
	functions: {
		effect: () => Promise<any>;
		onSuccess: (result: any) => void;
		callback: () => void;
	},
	dependencies: any[],
	disabled?: boolean
) => void = ({ effect, onSuccess, callback }, dependencies, disabled) => {
	const isMounted = React.useRef(false);

	React.useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	React.useEffect(() => {
		if (disabled) return;
		effect().then(result => {
			if (result == null) return;
			if (isMounted.current) onSuccess(result);
		});

		return callback;
	}, dependencies);
};
