import { Theme } from "@mui/material";

declare module "@mui/material/Divider" {
	interface DividerPropsVariantOverrides {
		["fullwidth-primary"]: true;
		["fullwidth-secondary"]: true;
		["fullwidth-grey"]: true;
		["fullheight-grey"]: true;
	}
}

export const getMuiDividerStyles = (theme: Theme) => {
	const variants = [
		{
			props: { variant: "fullwidth-primary" },
			style: { border: `1px solid ${theme.palette.primary.main}` }
		},
		{
			props: { variant: "fullwidth-secondary" },
			style: { borderBottom: `1px solid ${theme.palette.secondary.main + "1a"}` }
		},
		{
			props: { variant: "fullwidth-grey" },
			style: { borderBottom: `1px solid ${theme.palette.grey[300]}` }
		},
		{
			props: { variant: "fullheight-grey" },
			style: { borderRight: `1px solid ${theme.palette.grey[300]}`, height: "100%" }
		}
	];

	return { variants };
};
