import React from "react";
import { DashboardHeaderCellContentAdaptor } from "../../../util/adaptors/dashboard-header-cell-content.adaptor";
import { Column } from "../../../util/configs/dashboard-table";
import { useTableContext } from "../../../util/contexts/table.context";
import { useLanguageContext } from "../../../util/contexts/language.context";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";

export const DashboardHeaderCellContent: React.FC<{
	column: Column;
	textColor: string;
}> = ({ column: { displayBy, requestBy, type, sortable }, textColor }) => {
	const { sortedField, sortOrder, tableKey } = useTableContext();
	const { getLocalizedText } = useLanguageContext();
	const { isMobile } = useResponsive();

	const text = getLocalizedText(`table_${tableKey}_${displayBy}`);

	return DashboardHeaderCellContentAdaptor[headerColumnTypeConverter(type)](
		text,
		{ sortable: sortable && !isMobile, isSorted: sortedField === requestBy, sortOrder },
		textColor
	);
};

const headerColumnTypeConverter = (type: string) => {
	switch (type) {
		case "select":
		case "delete":
		case "inspect":
			return type;
		default:
			return "text";
	}
};
