import { Stack } from "@mui/material";
import { LocalizedButton } from "../../elements";

export const DateRangeCalenderFooter: React.FC<{
	clearDates: () => void;
	submitDates: () => void;
	disabled: boolean;
}> = ({ clearDates, submitDates, disabled }) => (
	<Stack
		direction="row"
		width="100%"
		justifyContent="space-between"
		p="0.75rem 1rem"
		sx={{ borderTop: "1px solid var(--theme-grey-300)" }}
	>
		<LocalizedButton
			disabled={disabled}
			onClick={clearDates}
			variant="noStroke-mini"
			localizationKey="date-range-filter_clear-button"
		/>
		<LocalizedButton
			disabled={disabled}
			onClick={submitDates}
			variant="newPurple-mini"
			localizationKey="date-range-filter_save-button"
		/>
	</Stack>
);
