import { Stack, useTheme } from "@mui/material";
import { AdminHomepageTableHeading } from "../headings";
import { LocalizedButton } from "../../elements";
import { AdminHomepageTableHeadingProps } from "../types";

interface Props extends AdminHomepageTableHeadingProps {
	handleClick: () => void;
	padding: string | Record<string, string>;
}

export const AdminHomepageTableHeader: React.FC<Props> = ({
	handleClick,
	padding,
	...headingProps
}) => {
	const theme = useTheme();

	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			borderBottom={{ md: `1px solid ${theme.palette.grey[300]}` }}
			padding={padding}
		>
			<AdminHomepageTableHeading {...headingProps} />
			<LocalizedButton
				variant="noStroke-dashboard"
				localizationKey="admin-homepage-table_header_button"
				onClick={handleClick}
			/>
		</Stack>
	);
};
