import React from "react";
import { DashboardTableBodyRow as BodyRow } from "../../../util/theme/styled-components";
import { Identifiable } from "../../../util/adaptors/types";
import { DashboardTableBodyCell, DashboardTableBodyRowEndCell } from "../cells";
import { useTableContext } from "../../../util/contexts/table.context";
import { DashboardTableConfig } from "../../../util/configs/dashboard-table";
import { DashboardBodyCellContent } from "../contents";

interface BodyProps {
	element: Identifiable;
}

export const DashboardTableBodyRow: React.FC<BodyProps> = ({ element }) => {
	const { handleSelectElement, selectedElements, table, isLoading } = useTableContext();

	const columns = DashboardTableConfig[table].visible;

	const cells = columns.map((column, index) => {
		const rowEnd = index === columns.length - 1;
		const Cell = rowEnd ? DashboardTableBodyRowEndCell : DashboardTableBodyCell;
		return (
			<Cell
				handleClick={() =>
					column.action && !isLoading ? handleSelectElement(element, column.action) : null
				}
				Content={<DashboardBodyCellContent element={element} column={column} />}
				key={index}
			/>
		);
	});

	const isDeactivated = Object.keys(element).includes("active") && !element.active;
	const elementIsSelected = selectedElements.some(
		({ id: selectedId }) => selectedId === element.id
	);

	return (
		<BodyRow hover $isDeactivated={isDeactivated} $isselected={elementIsSelected}>
			{cells}
		</BodyRow>
	);
};
