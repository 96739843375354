import { TableEnum } from "../../types/dashboard-table.types";
import { Column } from "./column";
import { SearchableColumn } from "./searchable-column";
import { FilterableColumn } from "./filterable-column";

const addDeleteToColumns = (columns: Column[]) => [
	...columns,
	new Column({
		displayBy: "delete",
		type: "delete",
		sortable: false,
		action: "delete"
	})
];
const addSelectToColumns = (columns: Column[]) => [
	new Column({
		displayBy: "select",
		type: "select",
		sortable: false
	}),
	...columns
];

export const dashboardTableColumns = {
	[TableEnum.adminActivities]: [
		new FilterableColumn({
			displayBy: "resourceName",
			type: "resourceName",
			action: null,
			variableLocalizationKey: `table_${TableEnum.adminActivities}_resourceName_<VARIABLE>`,
			isHeaderColumn: true,
			rowOrder: 1
		}),
		new FilterableColumn({
			displayBy: "userFullName",
			type: "adminActivityUserInformation",
			action: null,
			requestBy: "user.fullName"
		}),
		new FilterableColumn({
			displayBy: "action",
			type: "adminActivity",
			action: null,
			variableLocalizationKey: `table_${TableEnum.adminActivities}_action_<VARIABLE>`,
			isHeaderColumn: true,
			rowOrder: 2
		}),
		new FilterableColumn({
			displayBy: "createdAt",
			action: null,
			type: "dateRangeWithTime",
			defaultSort: "asc",
			filterableColumnArgs: { allowMultipleSelection: false }
		}),
		new Column({
			displayBy: "inspect",
			action: null,
			sortable: false,
			type: "inspect",
			isFooterColumn: true
		})
	],
	[TableEnum.awards]: addDeleteToColumns([
		new SearchableColumn({ displayBy: "name", isHeaderColumn: true, rowOrder: 1 }),
		new SearchableColumn({ displayBy: "description" }),
		new FilterableColumn({ displayBy: "language" }),
		new FilterableColumn({
			displayBy: "date",
			type: "oneYearDateRange",
			defaultSort: "asc",
			isHeaderColumn: true,
			rowOrder: 2
		})
	]),
	[TableEnum.announcements]: addDeleteToColumns([
		new SearchableColumn({ displayBy: "title" }),
		new SearchableColumn({ displayBy: "content" }),
		new FilterableColumn({ displayBy: "type", isHeaderColumn: true, rowOrder: 1 }),
		new FilterableColumn({ displayBy: "language" }),
		new FilterableColumn({
			displayBy: "date",
			type: "oneYearDateRange",
			defaultSort: "desc",
			isHeaderColumn: true,
			rowOrder: 2
		})
	]),
	[TableEnum.branches]: addDeleteToColumns([
		new SearchableColumn({ displayBy: "name", isHeaderColumn: true, rowOrder: 1 }),
		new FilterableColumn({ displayBy: "city", defaultSort: "asc" }),
		new SearchableColumn({ displayBy: "address" }),
		new Column({ displayBy: "phoneNumber" }),
		new Column({ displayBy: "fax" }),
		new Column({ displayBy: "whatsapp" }),
		new FilterableColumn({ displayBy: "language" })
	]),
	[TableEnum.checks]: addSelectToColumns([
		new SearchableColumn({ displayBy: "checkNumber" }),
		new Column({
			displayBy: "checkAmount",
			type: "currency",
			isHeaderColumn: true,
			rowOrder: 1
		}),
		new FilterableColumn({
			displayBy: "checkEntryDate",
			type: "dateRange",
			filterableColumnArgs: { allowMultipleSelection: false }
		}),
		new FilterableColumn({
			displayBy: "checkDueDate",
			type: "dateRange",
			defaultSort: "desc",
			filterableColumnArgs: { allowMultipleSelection: false }
		}),
		new FilterableColumn({ displayBy: "checkBankName" }),
		new SearchableColumn({ displayBy: "checkDrawer" }),
		new FilterableColumn({
			displayBy: "checkStatus",
			type: "chip",
			variableLocalizationKey: `table_${TableEnum.checks}_checkStatus_<VARIABLE>`,
			sortable: false,
			isHeaderColumn: true,
			rowOrder: 2
		})
	]),
	[TableEnum.homepageChecks]: [
		new Column({
			displayBy: "checkNumber",
			sortable: false,
			action: null
		}),
		new Column({
			displayBy: "checkAmount",
			type: "currency",
			sortable: false,
			action: null,
			isHeaderColumn: true,
			rowOrder: 1
		}),
		new Column({
			displayBy: "checkEntryDate",
			type: "oneYearDateRange",
			sortable: false,
			action: null
		}),
		new Column({
			displayBy: "checkDueDate",
			type: "oneYearDateRange",
			sortable: false,
			defaultSort: "desc",
			action: null
		}),
		new Column({
			displayBy: "checkBankName",
			sortable: false,
			action: null
		}),
		new Column({
			displayBy: "checkDrawer",
			sortable: false,
			action: null
		}),
		new FilterableColumn({
			displayBy: "checkStatus",
			type: "chip",
			variableLocalizationKey: `table_${TableEnum.checks}_checkStatus_<VARIABLE>`,
			sortable: false,
			action: null,
			isHeaderColumn: true,
			rowOrder: 2
		})
	],
	[TableEnum.documents]: addDeleteToColumns([
		new SearchableColumn({ displayBy: "name", isHeaderColumn: true, rowOrder: 1 }),
		new FilterableColumn({ displayBy: "fileExtension" }),
		new Column({ displayBy: "link" }),
		new FilterableColumn({
			displayBy: "pageName",
			requestBy: "page.name"
		}),
		new FilterableColumn({ displayBy: "language" }),
		new FilterableColumn({
			displayBy: "date",
			type: "oneYearDateRange",
			filterableColumnArgs: { allowMultipleSelection: false },
			defaultSort: "asc",
			isHeaderColumn: true,
			rowOrder: 2
		})
	]),
	[TableEnum.faqs]: addDeleteToColumns([
		new SearchableColumn({
			displayBy: "question",
			isHeaderColumn: true,
			rowOrder: 1
		}),
		new SearchableColumn({ displayBy: "answer" }),
		new FilterableColumn({ displayBy: "category" }),
		new FilterableColumn({ displayBy: "language" }),
		new Column({
			displayBy: "index",
			type: "index",
			defaultSort: "desc"
		})
	]),
	[TableEnum.invoices]: [
		new FilterableColumn({
			displayBy: "invoiceEntryDate",
			action: null,
			type: "dateRange",
			defaultSort: "desc",
			filterableColumnArgs: { allowMultipleSelection: false }
		}),
		new FilterableColumn({
			displayBy: "invoiceDueDate",
			action: null,
			type: "dateRange",
			filterableColumnArgs: { allowMultipleSelection: false }
		}),
		new SearchableColumn({ displayBy: "invoiceNumber", action: null }),
		new Column({
			displayBy: "invoiceAmount",
			action: null,
			type: "currency",
			isHeaderColumn: true,
			rowOrder: 1
		}),
		new SearchableColumn({ displayBy: "invoiceDrawer", action: null })
	],
	[TableEnum.jobOpenings]: addDeleteToColumns([
		new SearchableColumn({ displayBy: "title", isHeaderColumn: true, rowOrder: 1 }),
		new SearchableColumn({ displayBy: "description" }),
		new Column({ displayBy: "requirements" }),
		new Column({ displayBy: "referenceId" }),
		new FilterableColumn({ displayBy: "city", defaultSort: "asc" }),
		new FilterableColumn({ displayBy: "language" })
	]),
	[TableEnum.localizations]: addDeleteToColumns([
		new SearchableColumn({
			displayBy: "key",
			defaultSort: "desc",
			isHeaderColumn: true,
			rowOrder: 1
		}),
		new SearchableColumn({ displayBy: "text" }),
		new FilterableColumn({ displayBy: "language" })
	]),
	[TableEnum.news]: addDeleteToColumns([
		new SearchableColumn({ displayBy: "title", isHeaderColumn: true, rowOrder: 1 }),
		new SearchableColumn({ displayBy: "description" }),
		new FilterableColumn({
			displayBy: "highlighted",
			type: "boolean",
			variableLocalizationKey: `table_${TableEnum.news}_highlighted_<VARIABLE>`,
			filterableColumnArgs: { allowMultipleSelection: false }
		}),
		new FilterableColumn({ displayBy: "language" }),
		new FilterableColumn({
			displayBy: "date",
			type: "oneYearDateRange",
			filterableColumnArgs: { allowMultipleSelection: false },
			defaultSort: "asc",
			isHeaderColumn: true,
			rowOrder: 2
		})
	]),
	[TableEnum.pages]: addDeleteToColumns([
		new SearchableColumn({ displayBy: "name", isHeaderColumn: true, rowOrder: 1 }),
		new SearchableColumn({ displayBy: "route" }),
		new Column({
			displayBy: "templateName",
			requestBy: "template.name"
		}),
		new Column({
			displayBy: "sectionName",
			requestBy: "section.name",
			defaultSort: "desc",
			isHeaderColumn: true,
			rowOrder: 2
		}),
		new FilterableColumn({ displayBy: "language" }),
		new FilterableColumn({
			displayBy: "index",
			type: "index"
		})
	]),
	[TableEnum.sections]: addDeleteToColumns([
		new SearchableColumn({ displayBy: "name", isHeaderColumn: true, rowOrder: 1 }),
		new FilterableColumn({
			displayBy: "visible",
			type: "boolean",
			variableLocalizationKey: `table_${TableEnum.sections}_visible_<VARIABLE>`,
			filterableColumnArgs: { allowMultipleSelection: false }
		}),
		new FilterableColumn({ displayBy: "language" }),
		new FilterableColumn({
			displayBy: "index",
			type: "index",
			defaultSort: "desc"
		})
	]),
	[TableEnum.testimonials]: addDeleteToColumns([
		new FilterableColumn({
			displayBy: "date",
			type: "dateRange",
			isHeaderColumn: true,
			rowOrder: 2
		}),
		new SearchableColumn({
			displayBy: "clientName",
			isHeaderColumn: true,
			rowOrder: 1
		}),
		new SearchableColumn({ displayBy: "text", sortable: false }),
		new FilterableColumn({ displayBy: "language" }),
		new Column({ displayBy: "order", type: "index" })
	]),
	[TableEnum.userRoleSubscriptions]: [],
	[TableEnum.users]: addSelectToColumns([
		new Column({
			displayBy: "userInformation",
			type: "userInformation",
			requestBy: "firstName",
			defaultSort: "asc",
			isHeaderColumn: true,
			rowOrder: 1
		}),
		new SearchableColumn({ displayBy: "email" }),
		new SearchableColumn({ displayBy: "username" }),
		new FilterableColumn({
			displayBy: "active",
			type: "boolean",
			sortable: false,
			hidden: true,
			filterableColumnArgs: { allowMultipleSelection: false },
			variableLocalizationKey: `table_${TableEnum.users}_active_<VARIABLE>`
		}),
		new SearchableColumn({ displayBy: "userCode" }),
		new FilterableColumn({
			displayBy: "userRoles",
			type: "userRoles",
			sortable: false,
			requestBy: "userRoleSubscriptions.userRoleId",
			variableLocalizationKey: `table_${TableEnum.users}_userRoles_<VARIABLE>`
		})
	])
};
