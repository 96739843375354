import { Box, Alert, Dialog, Drawer } from "@mui/material";
import { useFormContext } from "../../../util/contexts/form.context";
import { BranchDAO } from "../../../util/generators/types";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { BranchDetailsContainer } from "../../containers";
import { AdminPanelBranchesForm } from "../forms";
import React from "react";
import { City } from "../../containers/types";
import { AdminDialogProps } from "../types";
import { useTableContext } from "../../../util/contexts/table.context";
import { AdminDialogHeader } from "../headers";

export const AdminBranchesDialog: React.FC<AdminDialogProps> = ({
	handleDialogClose,
	dialogOpen
}) => {
	const { handleFormSubmit, formError, values } = useFormContext();
	const { selectedElements } = useTableContext();
	const selectedElementId = selectedElements[0]?.id;

	const { city } = values;
	const branches = {
		[city]: [values] as unknown as BranchDAO[]
	};
	const { isMobile } = useResponsive();
	const dialogMargin = isMobile ? "1.88rem" : "15.0rem";

	const BranchDetails = React.useMemo(
		() => (
			<BranchDetailsContainer
				cities={[{ city }] as City[]}
				indexOfCity={0}
				city={city}
				handleClick={() => null}
				branches={branches}
			/>
		),
		[values]
	);

	const handleSubmit = (_: React.MouseEvent<Element, MouseEvent>, payload?: any) =>
		handleFormSubmit(selectedElementId, payload);

	return (
		<Dialog
			fullWidth={true}
			maxWidth="xl"
			open={dialogOpen}
			onClose={handleDialogClose}
			sx={{ marginLeft: dialogMargin }}
		>
			{isMobile ? (
				<>
					<AdminDialogHeader
						localizationKey="admin_panel_create_branch_text"
						handleClose={handleDialogClose}
					/>
					<Box overflow="auto">
						{formError && <Alert severity="error">{formError}</Alert>}
						<AdminPanelBranchesForm handleSubmit={handleSubmit} />
					</Box>
				</>
			) : (
				<>
					<Drawer variant="permanent" open={true}>
						<AdminPanelBranchesForm handleSubmit={handleSubmit} />
					</Drawer>
					{formError && <Alert severity="error">{formError}</Alert>}
					<Box px={4} py={4}>
						{BranchDetails}
					</Box>
				</>
			)}
		</Dialog>
	);
};
