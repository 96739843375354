import React from "react";
import { Identifiable } from "../../../util/adaptors/types";
import { Column } from "../../../util/configs/dashboard-table";
import { DashboardBodyCellContentAdaptor } from "../../../util/adaptors/dashboard-body-cell-content.adaptor";
import { useTableContext } from "../../../util/contexts/table.context";
import { DashboardBodySkeletonCellAdaptor } from "../../../util/adaptors/dashboard-body-skeleton-cell.adaptor";

export const DashboardBodyCellContent: React.FC<{
	element: Identifiable;
	column: Column;
}> = ({ element, column }) => {
	const { isLoading } = useTableContext();
	const cellValue = element[column.displayBy] ?? "---";
	return isLoading
		? DashboardBodySkeletonCellAdaptor[column.type]()
		: DashboardBodyCellContentAdaptor[column.type](
				cellValue,
				element,
				column.filterOptions
		  );
};
