import { getMuiPalette } from "../palette.theme";

const palette = getMuiPalette();

const base = {
	flex: 1,
	display: "flex",
	flexDirection: "column" as const,
	alignItems: "center",
	padding: "1.25rem",
	borderWidth: "0,09375rem",
	borderRadius: "0.5rem",
	outline: "none"
};

const focused = {
	borderStyle: "dashed",
	backgroundColor: `${palette.grey[300]}36`,
	color: palette.grey[600],
	borderColor: palette.secondary.main
};

const accept = {
	borderStyle: "dashed",
	backgroundColor: `${palette.grey[300]}36`,
	color: palette.grey[600]
};

const reject = {
	borderStyle: "dashed",
	backgroundColor: `${palette.grey[300]}36`,
	color: palette.grey[600],
	borderColor: palette.error.main
};

export { base, focused, accept, reject };
