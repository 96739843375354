import { Stack } from "@mui/material";
import { DashboardFiltersContainer, DashboardSearchContainer } from "../../containers";
import {
	DashboardTablePaper,
	SearchAndFiltersStack
} from "../../../util/theme/styled-components";
import { DashboardTableWrapper } from "./dashboard.table";
import { DashboardTableHeaderRow } from "../rows";
import { DashboardTableBody } from "../bodies";
import { DashboardTableFooter } from "../footers";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { useTableContext } from "../../../util/contexts/table.context";
import { MobileTable } from "./mobile.table";
import { EmptyTableViewContent } from "../contents";
import { useTableQueryParams } from "../../../util/hooks/useTableQueryParams.hook";

export const ClientTable: React.FC = () => {
	const { isMobile } = useResponsive();
	const { tableElements } = useTableContext();

	const numberOfDisplayableChecks = tableElements.length;

	useTableQueryParams();

	const [desktopTable, mobileTable] =
		numberOfDisplayableChecks > 0
			? [
					<DashboardTablePaper>
						<DashboardTableWrapper>
							<DashboardTableHeaderRow variant="secondary" />
							<DashboardTableBody />
						</DashboardTableWrapper>
						<DashboardTableFooter />
					</DashboardTablePaper>,
					<MobileTable />
			  ]
			: [
					<DashboardTablePaper>
						<DashboardTableWrapper>
							<DashboardTableHeaderRow variant="secondary" />
						</DashboardTableWrapper>
						<EmptyTableViewContent />
					</DashboardTablePaper>,
					<MobileTable />
			  ];

	const tableContent = isMobile ? mobileTable : desktopTable;

	return (
		<Stack spacing={{ xs: "1rem", md: "1.5rem" }} mt="-0.5rem">
			<SearchAndFiltersStack>
				<DashboardSearchContainer />
				<DashboardFiltersContainer />
			</SearchAndFiltersStack>
			{tableContent}
		</Stack>
	);
};
