import { Skeleton, SkeletonProps } from "@mui/material";

export const TextSkeleton: React.FC<SkeletonProps> = ({ sx, ...props }) => (
	<Skeleton
		animation="wave"
		width="100%"
		height="0.875rem"
		variant="rounded"
		sx={{ maxWidth: { xs: "10rem", md: "18rem" }, minWidth: "4rem", ...sx }}
		{...props}
	/>
);
