import { IconButton, Stack } from "@mui/material";
import { DashboardPageItem } from "../../types";
import { LocalizedGenericBody } from "../../elements";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { MenuIcon } from "../../components";

interface Props {
	pageItem: DashboardPageItem;
}

export const DashboardContentWrapper: React.FC<Props> = ({ pageItem, children }) => {
	const { setIsOpen } = useSidebarContext();
	const { isMobile } = useResponsive();

	const openSidebar = () => setIsOpen(true);

	return (
		<Stack
			spacing={{ xs: "1rem", lg: "1.25rem" }}
			sx={{ height: "100%", width: "100%", maxWidth: "88rem" }}
		>
			<Stack direction="row" spacing="0.75rem" alignItems="center" paddingLeft="0.25rem">
				{isMobile ? (
					<IconButton
						data-cy="sidebar-hamburger-button"
						size="large"
						onClick={openSidebar}
						sx={{
							padding: "0rem",
							borderRadius: 0,
							".MuiPaper-root": { borderRadius: 0 }
						}}
					>
						<MenuIcon />
					</IconButton>
				) : null}
				<LocalizedGenericBody
					localizationKey={pageItem.localizationKey}
					variants={{ mobile: "h4-primary", desktop: "h3-primary-semi-bold" }}
				/>
			</Stack>
			{children}
		</Stack>
	);
};
