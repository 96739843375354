import documentsIcon from "../../assets/images/dashboard/dashboard-sidebar-documents-icon.svg";
import newsIcon from "../../assets/images/dashboard/dashboard-sidebar-news-icon.svg";
import faqsIcon from "../../assets/images/dashboard/dashboard-sidebar-faqs-icon.svg";
import announcementsIcon from "../../assets/images/dashboard/dashboard-sidebar-announcements-icon.svg";
import assetsIcon from "../../assets/images/dashboard/dashboard-sidebar-assets-icon.svg";
import awardsIcon from "../../assets/images/dashboard/dashboard-sidebar-awards-icon.svg";
import branchesIcon from "../../assets/images/dashboard/dashboard-sidebar-branches-icon.svg";
import jobOpeningsIcon from "../../assets/images/dashboard/dashboard-sidebar-jobOpenings-icon.svg";
import pagesIcon from "../../assets/images/dashboard/dashboard-sidebar-pages-icon.svg";
import sectionsIcon from "../../assets/images/dashboard/dashboard-sidebar-sections-icon.svg";
import testimonialsIcon from "../../assets/images/dashboard/dashboard-sidebar-testimonials-icon.svg";
import localizationsIcon from "../../assets/images/dashboard/dashboard-sidebar-localizations-icon.svg";
import userPageIcon from "../../assets/images/dashboard/dashboard_user_page_icon.svg";
import adminActivitiesIcon from "../../assets/images/dashboard/dashboard-admin-activities.icon.svg";

import checksInquiryIcon from "../../assets/images/dashboard/dashboard-sidebar-checks-inquiry-icon.svg";
import invoicesInquiryIcon from "../../assets/images/dashboard/dashboard-sidebar-invoices-inquiry-icon.svg";

export const AdminActivitiesIconDictionary: Record<string, string> = {
	"admin-activities": adminActivitiesIcon,
	announcements: announcementsIcon,
	assets: assetsIcon,
	awards: awardsIcon,
	branches: branchesIcon,
	checks: checksInquiryIcon,
	clients: adminActivitiesIcon,
	documents: documentsIcon,
	faqs: faqsIcon,
	invoices: invoicesInquiryIcon,
	job_openings: jobOpeningsIcon,
	localizations: localizationsIcon,
	news: newsIcon,
	pages: pagesIcon,
	releases: adminActivitiesIcon,
	sections: sectionsIcon,
	testimonials: testimonialsIcon,
	users: userPageIcon
};
