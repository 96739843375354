import { Theme } from "@mui/material";
import { ColorDictionary } from "../../dictionaries/color.dictionary";

declare module "@mui/material/Button" {
	interface ButtonPropsVariantOverrides {
		primary: true;
		secondary: true;
		header: true;
		location: true;
		submit: true;
		document: true;
		cta: true;
		publish: true;
		selectBar: true;
		cancel: true;
		newPrimary: true;
		"newPrimary-mini": true;
		"newPrimary-dashboard": true;
		newSecondary: true;
		"newSecondary-mini": true;
		"newSecondary-dashboard": true;
		newPurple: true;
		"newPurple-mini": true;
		noStroke: true;
		"noStroke-mini": true;
		"noStroke-dashboard": true;
		tablePagination: true;
	}
}

export const getMuiButtonStyles = (theme: Theme) => {
	const styleClear = {
		background: "unset",
		border: "unset",
		color: "unset"
	};

	const styleOverrides = {
		root: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",

			padding: "0.625rem 1.0625rem",

			textTransform: "unset",

			borderRadius: "0.5rem",

			width: "max-content",
			minWidth: "13.5rem",
			minHeight: "3rem"
		}
	};

	const commonStyles = {
		".MuiButton-startIcon": { marginRight: "0", marginLeft: "0" },
		".MuiButton-endIcon": { marginRight: "0", marginLeft: "0" },
		gap: "0.5rem"
	};

	const defaultStyles = {
		padding: "0.875rem 1rem",
		justifyContent: "space-between",

		".MuiTypography-root": {
			margin: "auto",
			fontSize: "1rem",
			fontWeight: 400,
			lineHeight: "100%"
		}
	};

	const miniStyles = {
		padding: "0.375rem 0.75rem",
		minWidth: "3.875rem",
		minHeight: "1.75rem",

		".MuiTypography-root": {
			margin: "auto",
			fontSize: "0.75rem",
			fontWeight: 500,
			lineHeight: "100%"
		}
	};

	const primaryColors = {
		background: theme.palette.button.primary.default,
		color: theme.palette.common.white + "!important",
		"&:hover": {
			background: theme.palette.button.primary.hover
		},
		"&:active": {
			background: theme.palette.button.primary.active
		},
		"&:disabled": {
			background: theme.palette.button.primary.disabled,
			color: theme.palette.common.white + "!important",
			opacity: 0.4
		}
	};

	const secondaryColors = {
		background: theme.palette.button.secondary.default,
		color: theme.palette.primary.main + "!important",
		border: `1px solid ${theme.palette.primary.main}`,
		"&:hover": {
			background: theme.palette.button.secondary.hover
		},
		"&:active": {
			background: theme.palette.button.secondary.active
		},
		"&:disabled": {
			background: theme.palette.button.secondary.disabled,
			color: theme.palette.primary.main + "!important",
			opacity: 0.4
		}
	};

	const dashboardColors = {
		background: theme.palette.button.dashboard.default,
		color: theme.palette.primary.main + "!important",
		border: "0.5px solid #d4d4d4",
		"&:hover": {
			background: theme.palette.button.dashboard.hover
		},
		"&:active": {
			background: theme.palette.button.dashboard.active
		},
		"&:disabled": {
			background: theme.palette.button.dashboard.disabled,
			color: theme.palette.common.white + "!important",
			opacity: 0.4
		}
	};

	const purpleColors = {
		background: theme.palette.button.purple.default,
		color: theme.palette.common.white,
		"&:hover": {
			background: theme.palette.button.purple.hover
		},
		"&:active": {
			background: theme.palette.button.purple.active
		},
		"&:disabled": {
			background: theme.palette.button.purple.disabled,
			color: theme.palette.common.white,
			opacity: 0.4
		}
	};

	const variants = [
		{
			props: { variant: "primary" },
			style: {
				background: theme.palette.primary.main,
				color: theme.palette.common.white,

				"&:hover": {
					background: theme.palette.primary.dark
				},
				"&:active": {
					background: "#0d0f2b"
				},
				"&:disabled": {
					color: theme.palette.common.white,
					opacity: 0.4
				}
			}
		},
		{
			props: { variant: "secondary" },
			style: {
				border: `1px solid ${theme.palette.primary.main}`,
				background: "white",

				"&:hover": {
					background: "rgba(96, 82, 143, 0.12)"
				},
				"&:active": {
					background: "rgba(96, 82, 143, 0.25)"
				},
				"&:disabled": {
					opacity: 0.4
				}
			}
		},
		{
			props: { variant: "header" },
			style: {
				width: "max-content",
				padding: 0,
				height: "1.75rem",
				marginBottom: "0.625rem",
				background: "transparent",
				color: theme.palette.primary.main,

				["&:focus"]: styleClear,
				["&:hover"]: styleClear,
				["&:active"]: styleClear
			}
		},
		{
			props: { variant: "location" },
			style: { opacity: 0.62 }
		},
		{
			props: { variant: "submit" },
			style: {
				background: theme.palette.primary.main,
				color: theme.palette.common.white,
				width: "unset",
				["&:hover"]: {
					background: theme.palette.primary.dark
				}
			}
		},
		{
			props: { variant: "document" },
			style: {
				width: "100%",
				height: "unset",

				display: "flex",
				justifyContent: "left",

				background: "transparent",
				color: theme.palette.secondary.main,

				borderRadius: "0",

				["&:active"]: {
					background: "transparent",
					color: theme.palette.secondary.main,
					borderRadius: "0",
					border: "none"
				},
				["&:hover"]: {
					background: "transparent",
					color: theme.palette.secondary.main,
					borderRadius: "0",
					border: "none"
				},
				["&:focus"]: {
					background: "transparent",
					color: theme.palette.secondary.main,
					borderRadius: "0",
					border: "none"
				}
			}
		},
		{
			props: { variant: "publish" },
			style: {
				width: "100%",
				color: theme.palette.common.white,
				background: `radial-gradient(83.33% 3000% at 4.92% 27.27%, ${theme.palette.secondary.main} 11.87%, #8fb59d 68.06%, #94c367 100%), linear-gradient(0deg, ${theme.palette.secondary.main}0d, ${theme.palette.secondary.main}0d), ${theme.palette.grey[50]}`,
				border: `1px solid ${theme.palette.secondary.main}94`,
				boxShadow: `0 0 1.1875rem 0.125rem ${theme.palette.secondary.main}29`,
				["&:hover"]: {
					background: `linear-gradient(0deg, ${theme.palette.secondary.main}52, ${theme.palette.secondary.main}52), radial-gradient(83.33% 3000% at 4.92% 27.27%, ${theme.palette.secondary.main} 11.87%, #8FB59D 68.06%, ${theme.palette.tertiary.light} 100%)`
				}
			}
		},
		{
			props: { variant: "selectBar" },
			style: {
				borderRadius: "0.5rem",
				border: `0.5px solid ${ColorDictionary["D4D4D4"]}`,
				background: ColorDictionary["#f3f3f3"]
			}
		},
		{
			props: { variant: "cancel" },
			style: {
				width: "100%",

				background: "transparent",
				color: theme.palette.secondary.main,

				borderRadius: "0.5rem",

				["&:hover"]: { background: "transparent" }
			}
		},
		{
			props: { variant: "tablePagination" },
			style: {
				textAlign: "center",
				background: "transparent",
				border: "none",
				minHeight: "unset",
				height: "2rem",

				"&:disabled": {
					opacity: 0.4
				},

				["&:MuiButtonBase-root"]: { background: "transparent" },
				["& .MuiButton-startIcon"]: {
					marginLeft: 0,
					marginRight: 0
				},
				["& .MuiButton-endIcon"]: {
					marginLeft: 0,
					marginRight: 0
				}
			}
		},
		{
			props: { variant: "newPrimary" },
			style: {
				...commonStyles,
				...defaultStyles,
				...primaryColors
			}
		},
		{
			props: { variant: "newPrimary-mini" },
			style: {
				...commonStyles,
				...miniStyles,
				...primaryColors
			}
		},
		{
			props: { variant: "newPrimary-dashboard" },
			style: {
				...commonStyles,
				...miniStyles,
				...dashboardColors
			}
		},
		{
			props: { variant: "newSecondary" },
			style: {
				...commonStyles,
				...defaultStyles,
				...secondaryColors
			}
		},
		{
			props: { variant: "newSecondary-mini" },
			style: {
				...commonStyles,
				...miniStyles,
				...secondaryColors
			}
		},
		{
			props: { variant: "newSecondary-dashboard" },
			style: {
				...commonStyles,
				...miniStyles,
				...secondaryColors
			}
		},
		{
			props: { variant: "newPurple" },
			style: {
				...commonStyles,
				...defaultStyles,
				...purpleColors
			}
		},
		{
			props: { variant: "newPurple-mini" },
			style: {
				...commonStyles,
				...miniStyles,
				...purpleColors
			}
		},
		{
			props: { variant: "noStroke" },
			style: {
				...commonStyles,
				...defaultStyles,
				...secondaryColors,
				border: "none"
			}
		},
		{
			props: { variant: "noStroke-mini" },
			style: {
				...commonStyles,
				...miniStyles,
				...secondaryColors,
				border: "none"
			}
		},
		{
			props: { variant: "noStroke-dashboard" },
			style: {
				...commonStyles,
				...miniStyles,
				...secondaryColors,
				border: "none",
				".MuiTypography-root": {
					margin: "auto",
					fontSize: "0.875rem",
					fontWeight: 500,
					lineHeight: "100%"
				}
			}
		}
	];

	return { styleOverrides, variants };
};
