import { Stack } from "@mui/material";
import { Identifiable } from "../../../util/adaptors/types";
import { Column } from "../../../util/configs/dashboard-table";
import { DashboardBodyCellContent, DashboardHeaderCellContent } from "../contents";

export const MobileTableItemBodyRow: React.FC<{
	column: Column;
	element: Identifiable;
}> = ({ column, element }) => (
	<Stack direction="row" justifyContent="space-between">
		<DashboardHeaderCellContent column={column} textColor="light-grey" />
		<DashboardBodyCellContent element={element} column={column} />
	</Stack>
);
