import React from "react";
import { Stack } from "@mui/material";
import {
	DashboardTableConfig,
	FilterableColumn
} from "../../util/configs/dashboard-table";
import { LocalizedGenericButton } from "../elements";
import { Clear } from "@mui/icons-material";
import { DashboardFilterInputContainer } from "./dashboard-filter-input.container";
import { useTableContext } from "../../util/contexts/table.context";
import { DateTime } from "luxon";

interface Props {
	clearFilteredColumns: () => void;
	clearEvent: React.MouseEvent | null;
	filteredColumns: FilterableColumn[];
	setFilteredColumns: React.Dispatch<React.SetStateAction<FilterableColumn[]>>;
}

export const FilterContainer: React.FC<Props> = ({
	clearFilteredColumns,
	clearEvent,
	filteredColumns,
	setFilteredColumns
}) => {
	const { table } = useTableContext();

	const filterableColumns = DashboardTableConfig[table].filterable.filter(
		({ hideFilter }) => !hideFilter
	);

	const handleChange = async (value: string[], relevantColumn: FilterableColumn) => {
		let valueArray = value;

		if (relevantColumn.type.includes("dateRange") && !!value) {
			valueArray = (valueArray as string[])
				.map(date => DateTime.fromISO(date))
				.map(date => encodeURIComponent(date.toISO()));
		}

		relevantColumn.selectedFilterValues =
			valueArray.includes("") || valueArray.length === 0 ? [] : valueArray;

		const newFilteredColumns = filterableColumns.filter(
			column => column.selectedFilterValues.length > 0
		);

		setFilteredColumns(newFilteredColumns);
	};

	const filterInputs = filterableColumns.map(column => (
		<DashboardFilterInputContainer
			key={column.displayBy}
			handleChange={value => handleChange(value, column)}
			column={column}
			table={table}
			clearEvent={clearEvent}
		/>
	));

	return (
		<Stack direction="row" spacing="1rem" alignItems="end" height="2rem">
			{filterInputs}
			{filteredColumns.length ? (
				<LocalizedGenericButton
					startIcon={<Clear sx={{ fontSize: "0.875rem" }} />}
					handleClick={clearFilteredColumns}
					localizationKey="clear_filters_button"
					mini
					sx={{ border: "none", display: "flex", alignItems: "center" }}
					textVariant="body4-primary-medium"
				/>
			) : null}
		</Stack>
	);
};
