import { Box, Stack, styled } from "@mui/material";
import { transientOptions } from "../options";

export const CityHeadingContainer = styled(Box)(() => ({
	paddingTop: "1.94rem",
	paddingBottom: "1.5rem",
	width: "100%",
	textAlign: "center",
	paddingLeft: 0,
	paddingRight: 0
}));

export const CityTopContainer = styled(Box)(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.common.white}`,
	width: "50%",
	marginLeft: "25%"
}));

export const CityBottomContainer = styled(Box)(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.common.white}`,
	width: "50%",
	marginLeft: "25%",
	textAlign: "center"
}));

export const CityHeadingFullContainer = styled(Box)(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.common.white}`,
	borderBottom: `1px solid ${theme.palette.common.white}`,
	[theme.breakpoints.down("md")]: {
		paddingTop: "0.5rem",
		paddingBottom: "0.5rem",
		width: "12.9rem"
	},
	paddingTop: "1.5rem",
	paddingBottom: "1.5rem",
	width: "min-content",
	minWidth: "8.63rem",
	textAlign: "center"
}));

export const DesktopAwardDot = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	zIndex: 1,
	width: 36,
	height: 36,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center"
}));

export const NewsBarBox = styled(Box)(
	({
		theme: {
			palette: { primary, secondary, tertiary },
			breakpoints
		}
	}) => ({
		background: `linear-gradient(90deg, ${primary.main} 1.79%, ${secondary.light} 61.04%, ${tertiary.light} 95.37%)`,
		border: "none",
		boxSizing: "border-box",
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		padding: "0.625rem 0",
		[breakpoints.down("md")]: {
			padding: "0.5rem 0"
		}
	})
);

export const ColoredCardDot = styled(Box)(() => ({
	height: "1rem",
	width: "1rem",

	border: "1px solid white",
	borderRadius: "50%"
}));

export const FaqDot = styled(Box)<{ index: number; openindex: number }>(
	({ theme, index, openindex }) => ({
		width: "1.5rem",
		height: "1.5rem",
		[theme.breakpoints.down("md")]: {
			width: "1rem",
			height: "1rem"
		},
		background:
			openindex === index ? theme.palette.primary.main : theme.palette.tertiary.main,
		borderRadius: "50%"
	})
);

export const FaqBox = styled(Box)(() => ({
	width: "100%",
	border: "1px solid rgba(0, 0, 0, 0.12)",
	borderRadius: "0.5rem"
}));

export const HomepageSectionBreak = styled(Box)(({ theme }) => ({
	background: theme.palette.common.white,
	padding: "0.313rem"
}));

export const CareerItemBox = styled(
	Box,
	transientOptions
)<{ $selected: boolean }>(({ $selected, theme }) => {
	const highlightedStyles = $selected
		? {
				border: `0.125rem solid ${theme.palette.tertiary.main}`
		  }
		: {
				background: theme.palette.primary.main
		  };

	return {
		...highlightedStyles,
		borderRadius: "0.5rem",
		width: "100%",
		padding: "1.38rem",
		paddingBottom: "0.75rem"
	};
});

export const CareerItemBar = styled(Box)<{ $selected: boolean }>(
	({ $selected, theme }) => {
		const highlightedStyles = $selected
			? {
					background: `linear-gradient(180deg,
					${theme.palette.tertiary.main} 0%,
					${theme.palette.secondary.main} 100%)`,
					boxShadow: "0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)"
			  }
			: {
					background: `linear-gradient(180deg,
					${theme.palette.common.white} 0%,
					${theme.palette.secondary.main} 100%)`
			  };

		return {
			...highlightedStyles,
			width: "0.25rem",
			height: "85%",
			borderRadius: "0.375rem"
		};
	}
);

export const PopupAnnouncementBox = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	textAlign: "justify",
	background: `linear-gradient(180deg, ${theme.palette.primary.light}cc 0%, ${theme.palette.tertiary.light}cc 100%)`,
	padding: "5.3125rem 2.5rem",
	borderRadius: "0.188rem"
}));

export const BannerAnnouncementBox = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	textAlign: "justify",
	padding: "4.0625rem 0",
	borderRadius: "0.188rem"
}));

export const TitleThreeSubtitleDot = styled(Box)(({ theme }) => ({
	borderRadius: "50%",
	width: "0.625rem",
	height: "0.625rem",
	background: theme.palette.primary.main
}));

export const StockPricesBarDot = styled(Box)(({ theme }) => ({
	borderRadius: "50%",
	width: "0.313rem",
	height: "0.313rem",
	background: theme.palette.primary.main
}));

export const StockPricesEventArrow = styled(
	Box,
	transientOptions
)<{ $isDecreaseArrow?: boolean }>(({ theme, $isDecreaseArrow }) => {
	const transientStyles = $isDecreaseArrow
		? {
				borderColor: `transparent transparent ${theme.palette.error.main} transparent`,
				transform: "rotate(180deg)"
		  }
		: {
				borderColor: `transparent transparent ${theme.palette.tertiary.main} transparent`
		  };

	return {
		width: 0,
		height: 0,
		borderStyle: "solid",
		borderWidth: "0 0.313rem 0.438rem 0.313rem",
		...transientStyles
	};
});

export const HighlightedBranchMapBox = styled(Box)(({ theme }) => ({
	minWidth: "7.94rem",
	background: theme.palette.common.white,
	borderRadius: "0.25rem 0 0 0.25rem"
}));

export const HighlightedBranchHeadingLine = styled(Box)(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.common.white}`
}));

export const NewsSearchItemDot = styled(Box)(({ theme }) => ({
	borderRadius: "50%",
	width: "0.188rem",
	height: "0.188rem",
	background: theme.palette.primary.main,
	opacity: "0.3"
}));

export const HeaderSearchBarSeperator = styled(Box)(({ theme }) => ({
	height: "1.88rem",
	width: "0",
	border: `1px solid ${theme.palette.grey[300]}`
}));

export const ClientLoginFormHelperBox = styled(Box)(({ theme }) => ({
	borderTop: `1px solid ${theme.palette.primary.main}1c`
}));

export const LoginContainerStack = styled(Stack)<{ backgroundimage?: string }>(
	({ theme, backgroundimage }) => ({
		minHeight: "100vh",
		minWidth: "100vw",
		alignItems: "center",
		justifyContent: "center",

		background: theme.palette.grey[300],
		backgroundImage: `url(${backgroundimage})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "0 100%",

		[theme.breakpoints.down("md")]: {
			backgroundImage: "none",
			justifyContent: "flex-start"
		}
	})
);

export const DashboardSidebarBox = styled(
	Box,
	transientOptions
)<{ $overflows?: boolean }>(({ $overflows, theme }) => ({
	background: theme.palette.common.white,
	borderRight: `1px solid ${theme.palette.grey[300]}`,
	height: "100vh",
	position: "sticky",

	display: "flex",
	flexDirection: "column",

	boxShadow: $overflows ? `0 0 3.75rem 0.25rem ${theme.palette.common.black}40` : "none"
}));

export const ConfirmationBox = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.75rem",

	maxWidth: "29.75rem",

	padding: "1rem",
	background: theme.palette.common.white,
	borderRadius: "1rem"
}));

export const FastEditReturnBox = styled(Box)(({ theme }) => ({
	minWidth: "17.5rem",

	background: theme.palette.grey[100],
	border: `1px solid ${theme.palette.grey[300]}`,
	borderRadius: "0.75rem"
}));

export const DashboardTableChip = styled(Box)<{
	variant:
		| "primary"
		| "secondary"
		| "tertiary"
		| "quaternary"
		| "quinary"
		| "senary"
		| "septenary"
		| "deactivated";
}>(({ theme, variant }) => ({
	width: "7.125rem",
	height: "1.75rem",

	background: `${theme.palette.chip[variant]}1a`,
	border: `1px solid ${theme.palette.chip[variant]}`,
	color: theme.palette.chip[variant],
	fontSize: "0.75rem",
	fontFamily: "Raleway",
	fontWeight: 500,
	lineHeight: "130%",
	textAlign: "center",

	borderRadius: "0.5rem",

	display: "flex",
	alignItems: "center",
	justifyContent: "center"
}));

export const TableSelectSnackbarDot = styled(Box)(({ theme }) => ({
	width: "0.1875rem",
	height: "0.1875rem",
	borderRadius: "50%",
	background: theme.palette.common.black,
	opacity: 0.3
}));

export const DashboardTableIndexBox = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",

	width: "1.25rem",
	height: "1.25rem",
	background: `${theme.palette.primary.main}1a`,
	borderRadius: "50%"
}));

export const MetaTagNumberBox = styled(Box)(({ theme }) => ({
	position: "absolute",
	left: "50%",
	bottom: 0,
	zIndex: "1",
	transform: "translate(-50%, calc(50% + 0.25rem))",
	background: theme.palette.grey[300],
	borderRadius: "50%",
	width: "1.22rem",
	height: "1.22rem",
	outline: `0.25rem solid ${theme.palette.common.white}`,
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
}));

export const DashboardMultipleSelectDot = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	height: "1rem",
	width: "1rem",
	position: "absolute",
	right: "-0.5rem",
	top: "-0.5rem",
	borderRadius: "20rem",
	zIndex: 1,
	background: theme.palette.primary.main
}));

export const ClientHomepageTimeToggleBox = styled(
	Box,
	transientOptions
)<{ $isSelected: boolean }>(({ theme, $isSelected }) => {
	const {
		palette: {
			common: { white },
			primary: { main: primaryMain }
		}
	} = theme;

	return {
		width: "100%",
		padding: "0.375rem 0.75rem",
		borderRadius: "1rem",
		background: $isSelected ? primaryMain : white,
		cursor: "pointer",
		userSelect: "none",
		":hover": {
			background: primaryMain + ($isSelected ? "" : "1a")
		},
		":active": {
			background: primaryMain + "aa"
		}
	};
});

export const TextEditBox = styled(Box)(({ theme }) => ({
	width: "1.75rem",
	height: "1.75rem",
	borderRadius: "0.25rem",
	position: "absolute",
	background: theme.palette.common.white,
	right: 0,
	top: 0
}));

export const PaginationUpAndDownArrowContainer = styled(Box)(({}) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	position: "absolute",
	right: "0.65rem",
	top: "50%",
	transform: "translateY(-50%)",
	pointerEvents: "none",
	"& > *:not(:last-child)": {
		marginBottom: "-0.55rem"
	}
}));

export const AdminHomepageTableBox = styled(Box)(({ theme }) => ({
	borderRadius: "0.75rem",
	border: `1px solid ${theme.palette.grey[300]}`,
	boxShadow: "var(--theme-shadow-table)",
	paddingBottom: "1rem"
}));
