import { TableHead } from "@mui/material";
import { DashboardTableConfig } from "../../../util/configs/dashboard-table";
import { DashboardTableHeaderRow as HeaderRow } from "../../../util/theme/styled-components";
import { DashboardTableHeaderCell, DashboardTableHeaderRowEndCell } from "../cells";
import { useTableContext } from "../../../util/contexts/table.context";
import { HeaderRowProps } from "../types";
import { DashboardHeaderCellContent } from "../contents";

export const DashboardTableHeaderRow: React.FC<HeaderRowProps> = ({
	variant,
	...props
}) => {
	const { handleSortClick, handleSelectAll, table } = useTableContext();

	const textColor = variant === "secondary" ? "white" : "primary";

	const columns = DashboardTableConfig[table].visible;

	const eventHandler = (type: string, requestBy: string, sortable: boolean) => {
		switch (type) {
			case "select":
				return handleSelectAll;
			case "inspect":
			case "delete":
				return () => null;
			default:
				return () => (sortable ? handleSortClick(requestBy) : null);
		}
	};

	const cells = columns.map((column, index) => {
		const { type, requestBy, sortable } = column;

		const rowEnd = index === columns.length - 1;
		const Cell = rowEnd ? DashboardTableHeaderRowEndCell : DashboardTableHeaderCell;

		return (
			<Cell
				handleClick={eventHandler(type, requestBy, sortable)}
				key={index}
				sortable={sortable}
				Content={<DashboardHeaderCellContent column={column} textColor={textColor} />}
			/>
		);
	});

	return (
		<TableHead {...props}>
			<HeaderRow variant={variant}>{cells}</HeaderRow>
		</TableHead>
	);
};
