import { Box, Stack } from "@mui/material";
import { DashboardTableConfig } from "../../../util/configs/dashboard-table";
import { useTableContext } from "../../../util/contexts/table.context";
import { Identifiable } from "../../../util/adaptors/types";
import { DashboardBodyCellContent, DashboardHeaderCellContent } from "../contents";

export const MobileTableItemHeader: React.FC<{
	element: Identifiable;
	isSelected: boolean;
	handleClick: () => void;
}> = ({ element, isSelected, handleClick }) => {
	const { handleSelectElement, table, isLoading } = useTableContext();

	const { headerColumns, actionColumn } = DashboardTableConfig[table];

	const [firstHeaderColumn, secondHeaderColumn] = headerColumns;

	const actionCell = actionColumn ? (
		<Box
			onClick={() =>
				isLoading ? null : handleSelectElement(element, actionColumn.action)
			}
			children={<DashboardBodyCellContent element={element} column={actionColumn} />}
		/>
	) : null;

	const borderColor = isSelected
		? "rgba(from var(--theme-secondary-main) r g b / 0.1)"
		: "var(--theme-grey-300)";

	return (
		<Stack
			spacing="1rem"
			direction="row"
			alignItems="center"
			px="1rem"
			pb="0.5rem"
			sx={{ borderBottom: `1px solid ${borderColor}` }}
		>
			{actionCell}
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				width="100%"
				onClick={handleClick}
			>
				<Stack direction="column">
					<DashboardHeaderCellContent column={firstHeaderColumn} textColor="grey" />
					<Box sx={{ "*": { fontWeight: "700 !important" } }}>
						<DashboardBodyCellContent element={element} column={firstHeaderColumn} />
					</Box>
				</Stack>
				{!!secondHeaderColumn ? (
					<DashboardBodyCellContent element={element} column={secondHeaderColumn} />
				) : null}
			</Stack>
		</Stack>
	);
};
