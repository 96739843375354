import { PaletteColor } from "@mui/material/styles";

interface ButtonVariant {
	default: string;
	hover: string;
	active: string;
	disabled: string;
}

interface ButtonColor {
	primary: ButtonVariant;
	secondary: ButtonVariant;
	dashboard: ButtonVariant;
	purple: ButtonVariant;
}

interface ChipColor {
	primary: string;
	secondary: string;
	tertiary: string;
	quaternary: string;
	quinary: string;
	senary: string;
	septenary: string;
	deactivated: string;
}

declare module "@mui/material/styles" {
	interface Palette {
		tertiary: PaletteColor;
		red: PaletteColor;
		chip: ChipColor;
		button: ButtonColor;
	}

	interface PaletteOptions {
		tertiary?: PaletteColor;
		red?: PaletteColor;
		chip?: ChipColor;
		button?: ButtonColor;
	}
}

export const getMuiPalette = () => {
	const common = {
		black: "#000000",
		white: "#ffffff"
	};

	const grey = {
		50: "#fcfcfc",
		100: "#f7f7f7",
		200: "#f0f0f0",
		300: "#ededed",
		400: "#e0e0e0",
		500: "#bdbdbd",
		600: "#9e9e9e",
		700: "#757575",
		800: "#616161",
		900: "#424242",
		A100: "#fafbff",
		A200: "#eeeeee",
		A400: "#bdbdbd",
		A700: "#616161"
	};

	const primary = {
		light: "#222447",
		main: "#222447",
		dark: "#030638",
		contrastText: "#182f43"
	};

	const secondary = {
		light: "#5c5E87",
		main: "#605294",
		dark: "#605294",
		contrastText: "#605294"
	};

	const tertiary = {
		light: "#93b275",
		main: "#9cbe73",
		dark: "#98b775",
		contrastText: "#98b775"
	};

	const success = { main: "#7fbf41" };
	const error = { main: "#c83211", dark: "#992d2d" };
	const warning = { main: "#f0ad4e" };

	const chip = {
		primary: "#222447",
		secondary: "#60528F",
		tertiary: "#9CBE73",
		quaternary: "#6B7280",
		senary: "#6646d3",
		septenary: "#60528f",
		deactivated: "#868B97"
	};

	const button = {
		primary: {
			default: "#222447",
			hover: "#030638",
			active: "#0d0f2b",
			disabled: "#222447"
		},
		secondary: {
			default: "#ffffff",
			hover: "#60528f1f",
			active: "#60528F40",
			disabled: "#ffffff"
		},
		dashboard: {
			default: "#f3f3f3",
			hover: "#60528f40",
			active: "#d4d4d4",
			disabled: "#222447"
		},
		purple: {
			default: "#605294",
			hover: "#4b4076",
			active: "#3b335d",
			disabled: "#605294"
		}
	};

	return {
		common,
		grey,
		primary,
		secondary,
		tertiary,
		success,
		error,
		warning,
		chip,
		button
	};
};
