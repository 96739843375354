import React from "react";
import { useDropzone } from "react-dropzone";
import { Box, useTheme } from "@mui/material";
import {
	base,
	focused,
	accept,
	reject
} from "../../../../util/theme/components/dnd-image.theme";
import { LocalizedGenericBody } from "../../../elements";

interface Props {
	imageCount: number;
	onCreate: (formData: FormData) => void;
	setImages: (files: File[]) => void;
}

export const DragAndDropImageBase: React.FC<Props> = ({
	imageCount,
	onCreate,
	setImages,
	children
}) => {
	const theme = useTheme();
	const [isDragging, setIsDragging] = React.useState<boolean>(false);

	const onDrop = React.useCallback(
		(acceptedFiles: File[]) => {
			if (!acceptedFiles || acceptedFiles.length === 0) return;
			const formData = new FormData();
			formData.set("asset", acceptedFiles[0]);
			onCreate(formData);
			setImages(acceptedFiles);
		},
		[onCreate]
	);

	const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
		useDropzone({
			onDrop: acceptedFiles => {
				onDrop(acceptedFiles);
				setIsDragging(false);
			},
			onDragEnter: () => setIsDragging(true),
			onDragLeave: () => {
				setIsDragging(false);
			},
			maxFiles: 1
		});

	const style = React.useMemo(() => {
		if (imageCount > 0) return;
		return {
			...base,
			...(isFocused ? focused : {}),
			...(isDragAccept ? accept : {}),
			...(isDragReject ? reject : {})
		};
	}, [imageCount, isFocused, isDragAccept, isDragReject]);

	return (
		<Box {...getRootProps({ style })} sx={{ height: "100%" }}>
			<input {...getInputProps()} />
			<Box onClick={e => e.stopPropagation()} sx={{ height: "100%" }}>
				{children}
			</Box>
			{isDragging && imageCount === 0 && (
				<LocalizedGenericBody
					variant="body2-primary-medium"
					localizationKey="drag_and_drop_image_focus_text"
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "relative",
						width: "26.875rem",
						height: "3.375rem",
						bottom: "1.75rem",
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.common.white,
						borderRadius: "0.75rem"
					}}
				/>
			)}
		</Box>
	);
};
