import { GenericImageContainer, LocalizedButton } from "../../elements";
import { TableEnum } from "../../../util/types/dashboard-table.types";

import startIcon from "../../../assets/images/activities-inspect-button-start-icon.svg";

export const InspectActivityButton: React.FC<{
	resourceName: string;
	resourceId: string;
}> = ({ resourceName, resourceId }) => (
	<LocalizedButton
		localizationKey={`table_${TableEnum.adminActivities}_inspect`}
		variant="newPrimary-dashboard"
		href={`/${resourceName}?id=${encodeURIComponent(resourceId)}`}
		startIcon={
			<GenericImageContainer width="0.875rem" imageSrc={startIcon} imageAlt="inspect" />
		}
		sx={{
			justifyContent: "center",
			width: "100%",
			".MuiTypography-root": { margin: "unset" }
		}}
	/>
);
