import { Box, Divider, Stack } from "@mui/material";
import {
	TableSelectSnackbarDot,
	TableSelectSnackbarStack
} from "../../../util/theme/styled-components";
import {
	LocalizedGenericButton,
	LocalizedGenericBody,
	LocalizedTypographyWithVariableSubject
} from "../../elements";
import { ClearButton } from "../buttons";
import { useTableContext } from "../../../util/contexts/table.context";
import { TableSelectSnackbarProps } from "../types";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";

export const TableSelectSnackbar: React.FC<TableSelectSnackbarProps> = ({
	buttonProps,
	selectedElementCountLocalizationKey
}) => {
	const {
		clearSelectedElements,
		handleSelectAll,
		allElementsSelected,
		selectedElements,
		totalElementCount
	} = useTableContext();

	const { isMobile, isTablet } = useResponsive();

	const numberOfSelectedElements = selectedElements.length;

	const selectedElementInfoProps = isMobile
		? {
				variable: `${numberOfSelectedElements}/${totalElementCount}`,
				localizationKey: selectedElementCountLocalizationKey + "_mobile"
		  }
		: {
				variable: numberOfSelectedElements.toString(),
				localizationKey: selectedElementCountLocalizationKey
		  };

	return (
		<TableSelectSnackbarStack direction="row" justifyContent="space-between">
			<Stack direction="row" alignItems="center">
				<Box p="0.75rem">
					<ClearButton handleClear={clearSelectedElements} />
				</Box>
				<Divider variant="fullheight-grey" />
				<Stack
					direction="row"
					alignItems="center"
					spacing={{ xs: "0.5rem", md: "0.75rem" }}
					pl={{ xs: "0.75rem", md: "1rem" }}
				>
					<LocalizedTypographyWithVariableSubject
						variant="body3-primary-medium"
						{...selectedElementInfoProps}
					/>
					{allElementsSelected ? null : (
						<Stack
							direction="row"
							alignItems="center"
							spacing={{ xs: "0.5rem", md: "0.75rem" }}
						>
							<TableSelectSnackbarDot />
							<Box onClick={handleSelectAll}>
								<LocalizedGenericBody
									variant="body3-secondary-medium"
									localizationKey="select_bar_select_all"
									sx={{ cursor: "pointer", a: { textDecoration: "underline" } }}
								/>
							</Box>
						</Stack>
					)}
				</Stack>
			</Stack>
			<Stack direction="row" alignItems="center" spacing="0.5rem">
				{buttonProps.map(({ handleClick, localizationKey, startIcon, sx }) => {
					const responsiveProps = isTablet
						? { children: startIcon, localizationKey: "" }
						: { localizationKey, startIcon };

					return (
						<LocalizedGenericButton
							key={localizationKey}
							handleClick={handleClick}
							variant="selectBar"
							textVariant="body4-primary-medium"
							mini
							sx={sx}
							{...responsiveProps}
						/>
					);
				})}
			</Stack>
		</TableSelectSnackbarStack>
	);
};
