import React from "react";
import { AdminActivitiesDialog, ConfirmationDialog, FormSnackbar } from "../components";
import {
	ActivitiesAdminCommunicator,
	UsersAdminCommunicator
} from "../../communicators/lider.http.communicator";
import { DialogKey } from "./types";
import { ReleaseStatusDAO, UserDAO } from "../../util/generators/types";
import { useAuthFormStatus } from "../../util/hooks/useAuthFormStatus.hook";
import { FormVariant } from "../components/types";
import { useSocketEvent } from "../../util/hooks/useSocketEvent.hook";

interface Props {
	user: UserDAO | null;
	isAdmin: boolean;
	adminCommunicator: UsersAdminCommunicator;
	activitiesCommunicator: ActivitiesAdminCommunicator;
	dialogOpen: Record<DialogKey, boolean>;
	logout: () => void;
	setIsPublished: (isPublished: boolean) => void;
	handleDialogToggle: (dialogKey: DialogKey) => void;
}

export const DashboardSidebarDialogContainer: React.FC<Props> = ({
	user,
	isAdmin,
	adminCommunicator,
	activitiesCommunicator,
	dialogOpen,
	logout,
	setIsPublished,
	handleDialogToggle
}) => {
	const {
		submissionStatus,
		resetSubmissionStatus,
		setSubmissionSuccess,
		setSubmissionInProgress,
		handleSubmissionError,
		setSubmissionError,
		setSubmissionStatus
	} = useAuthFormStatus();
	const [formVariant, setFormVariant] = React.useState<FormVariant>("publish");

	const tryResetPassword = async () => {
		setFormVariant("reset-password");
		if (!user?.email) return setSubmissionError();

		try {
			await adminCommunicator.resetPassword({ email: user.email });
			logout();
		} catch (error: any) {
			handleSubmissionError(error);
		}
	};

	const handleResetPasswordConfirmation = async () => {
		await tryResetPassword();
		handleDialogToggle("resetPassword");
	};

	const publish = async () => {
		if (!isAdmin) return;

		try {
			await activitiesCommunicator.publishStagingData();
			setSubmissionInProgress();
		} catch (error: any) {
			handleSubmissionError(error);
		}
	};

	const syncClients = async () => {
		setFormVariant("sync-clients");
		if (!isAdmin) return;

		try {
			await activitiesCommunicator.syncClients();
			setSubmissionSuccess();
		} catch (error: any) {
			handleSubmissionError(error);
		}
	};

	const handleSyncClientsConfirmation = async () => {
		await syncClients();
		handleDialogToggle("syncClients");
	};

	const sidebarWidthCollapsed = "5.5rem";

	const handlePublishConfirmation = async () => {
		await publish();
		handleDialogToggle("publish");
	};

	const ActivitiesDialog = React.useMemo(
		() => (
			<AdminActivitiesDialog
				handleDialogClose={() => handleDialogToggle("activities")}
				dialogOpen={dialogOpen.activities}
			/>
		),
		[dialogOpen]
	);

	const updateReleaseStatus = ({ released, releaseStatus }: ReleaseStatusDAO) => {
		setFormVariant("publish");
		setIsPublished(released);
		setSubmissionStatus(releaseStatus);
	};

	useSocketEvent("releaseStagingData", updateReleaseStatus);

	return (
		<>
			<FormSnackbar
				autoHideDuration={5000}
				handleClose={resetSubmissionStatus}
				submissionStatus={submissionStatus}
				formVariant={formVariant}
				offset={sidebarWidthCollapsed}
			/>
			<ConfirmationDialog
				handleClose={() => handleDialogToggle("publish")}
				handleConfirmation={handlePublishConfirmation}
				dialogOpen={dialogOpen.publish}
				variant="publish"
			/>
			<ConfirmationDialog
				handleClose={() => handleDialogToggle("syncClients")}
				handleConfirmation={handleSyncClientsConfirmation}
				dialogOpen={dialogOpen.syncClients}
				variant="syncClients"
			/>
			<ConfirmationDialog
				handleClose={() => handleDialogToggle("resetPassword")}
				handleConfirmation={handleResetPasswordConfirmation}
				dialogOpen={dialogOpen.resetPassword}
				variableSubject={user?.email}
				variant="resetPassword"
			/>
			<ConfirmationDialog
				handleClose={() => handleDialogToggle("logout")}
				handleConfirmation={logout}
				dialogOpen={dialogOpen.logout}
				variant="logout"
			/>
			{ActivitiesDialog}
		</>
	);
};
