import { Dialog, Drawer, Alert, Box } from "@mui/material";
import React from "react";
import { useFormContext } from "../../../util/contexts/form.context";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { PageTemplateMatcher } from "../../../util/matchers/template.matcher";
import { AdminPanelPageForm } from "../forms";
import { PageTemplateMatcherProps } from "../../../util/matchers/types";
import { AdminDialogProps } from "../types";
import { useTableContext } from "../../../util/contexts/table.context";
import { AdminDialogHeader } from "../headers";

export const AdminPagesDialog: React.FC<AdminDialogProps> = ({
	handleDialogClose,
	dialogOpen
}) => {
	const { handleFormSubmit, selectedTemplate, formError, values } = useFormContext();
	const { isMobile } = useResponsive();
	const { selectedElements } = useTableContext();
	const selectedElementId = selectedElements[0]?.id;

	const TemplateContainer = React.useMemo(
		() =>
			PageTemplateMatcher({
				...(values as PageTemplateMatcherProps),
				isAdmin: true,
				templateName: selectedTemplate ? selectedTemplate.name : "homepage"
			}),
		[values]
	);

	const dialogMargin = isMobile ? "1.88rem" : "15.0rem";

	const handleSubmit = (_: React.MouseEvent<Element, MouseEvent>, payload?: any) =>
		handleFormSubmit(selectedElementId, payload);

	return (
		<Dialog
			fullWidth={true}
			maxWidth="xl"
			open={dialogOpen}
			onClose={handleDialogClose}
			sx={{ marginLeft: dialogMargin }}
		>
			{isMobile ? (
				<>
					<AdminDialogHeader
						localizationKey="admin_panel_create_page_text"
						handleClose={handleDialogClose}
					/>
					<Box overflow="auto">
						{formError && <Alert severity="error">{formError}</Alert>}
						<AdminPanelPageForm handleSubmit={handleSubmit} />
					</Box>
				</>
			) : (
				<>
					<Drawer variant="permanent" open={true}>
						<AdminPanelPageForm handleSubmit={handleSubmit} />
					</Drawer>
					{formError && <Alert severity="error">{formError}</Alert>}
					{selectedTemplate && (
						<Box overflow="hidden auto">
							<TemplateContainer />
						</Box>
					)}
				</>
			)}
		</Dialog>
	);
};
