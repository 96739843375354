import { Skeleton, SkeletonProps } from "@mui/material";

export const CheckBoxSkeleton: React.FC<SkeletonProps> = ({ sx, ...props }) => (
	<Skeleton
		animation="wave"
		width="0.875rem"
		height="0.875rem"
		variant="rounded"
		sx={{ minWidth: "0.875rem", ...sx }}
		{...props}
	/>
);
