import { DialogEnum } from "../../../views/components/types";
import { Column } from "./column";
import { FilterableColumn } from "./filterable-column";
import { SearchableColumn } from "./searchable-column";

export class TableConfig {
	private actionTypes: string[] = ["delete", "select"];

	constructor(
		private columns: Column[],
		public dialog: DialogEnum | null = null,
		public tableInteractionDisabled = false
	) {
		if (!!dialog)
			this.columns = [
				...columns,
				new FilterableColumn({
					displayBy: "id",
					hidden: true,
					filterableColumnArgs: { hideFilter: true }
				})
			];
	}

	get all() {
		return this.columns;
	}

	get hidden() {
		return this.columns.filter(column => column.hidden);
	}

	get actionColumn() {
		return this.columns.find(column => this.actionTypes.includes(column.type));
	}

	get footerColumn() {
		return this.columns.find(column => column.isFooterColumn);
	}

	get headerColumns() {
		return this.columns
			.filter(column => column.isHeaderColumn)
			.sort((a, b) => a.rowOrder - b.rowOrder);
	}

	get visible() {
		return this.columns.filter(column => !column.hidden);
	}

	get dataColumns() {
		return this.columns
			.filter(column => !this.actionTypes.includes(column.type))
			.filter(column => !column.hidden);
	}

	get filterable() {
		return this.columns.filter(column => column.filterable) as FilterableColumn[];
	}

	get searchable() {
		return this.columns.filter(column => column.searchable) as SearchableColumn[];
	}
}
